import React from "react";

// STYLES
import './FlightsList.css';

// ASSETS
import RouteIcon from '../../assets/images/searchFlight/flights-route-icon.svg';

// COMPONENTS
import MobileNavbar from '../../components/hooks_components/mobileNavbar/MobileNavbar';
import FlightsListItem from "../../components/functional_components/flightsListItem/FlightsListItem";
import GoBackButton from "../../components/functional_components/goBackButton/GoBackButton";

// REDUX
import { useDispatch, connect } from "react-redux";
import { setAvailableServices } from "../../redux/ducks/AvailableServices";

import { Image, Col, Typography, Row } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../utils/properties";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

const FlightsList = (props) => {

  const { Text } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const goToFlightDetail = (flight) => () => {
    let availableServices = ["LLC"]
    if (flight.departureModel.hasLounges) {
      availableServices.push("VIPLOUNGE")
    }
    dispatch(setAvailableServices(availableServices))
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}pre-checkout`);
  }

  return (
    <Col className={'search-flights-list-screen-container'}>
      <Col className={'search-flights-list-header'}>
        <GoBackButton />

        <Image
          src={RouteIcon}
          alt={'flights-travel-icon'}
          preview={false}
        />

        <Row className={'search-flights-list-header-text-container'}>
          <Text className={'search-flights-list-header-text'}>{get(location.state.data, "from", location.state.data.flightNumber)}</Text>
          <Text className={'search-flights-list-header-text'}>{get(location.state.data, "to", location.state.data.airline)}</Text>
        </Row>
      </Col>

      {
        props.flightsDuck.flights.response.length > 0 &&
        <>
          {
            props.flightsDuck.flights.response.map((flight, i) => {
              return (
                <div key={i} onClick={goToFlightDetail(flight)}>
                  <FlightsListItem
                    stopovers={flight.connectionFlight}
                    departureDate={moment(flight.departingDate.dateLocal).format('D MMM')}
                    departureHours={moment(flight.departingDate.dateLocal).format('HH:mm')}
                    departureAirport={flight.departure}
                    arrivalDate={moment(flight.arrivingDate.dateLocal).format('D MMM')}
                    arrivalHours={moment(flight.arrivingDate.dateLocal).format('HH:mm')}
                    arrivalAirport={flight.arrival}
                    flightDuration={flight.flightProgressModel.flightDuration}
                    flightCode={flight.carrier + flight.numberFlight}
                    airline={flight.carrierModel.name}
                    airlineIcon={flight.carrierModel.airlineLogoUrl}
                  />
                </div>
              )
            })
          }
        </>
      }
      {
        props.flightsDuck.flights.response.length === 0 &&
        <Col className={"flight-no-results centered"}>
          <Text>{t("home.searchFlights.noResultsMessage")}</Text>
        </Col>
      }

      <MobileNavbar />
    </Col>
  )
}

const mapStateToProps = state => ({
  flightsDuck: state.flightsDuck
})

export default connect(mapStateToProps)(FlightsList)