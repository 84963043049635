import React, { useEffect } from "react";

// STYLES
import "./ProductDetail.css";

// COMPONENTS
import HowToCarousel from "../../components/functional_components/howToCarousel/HowToCarousel";
import CustomButton from "../../components/functional_components/customButton/CustomButton";
// import TravelInsuranceForm from "../../components/functional_components/travelInsuranceForm/TravelInsuranceForm";
import SEO from "../../components/functional_components/SEO/SEO";

// REDUX
import { connect, useDispatch } from "react-redux";

import { Col, Row, Image, Typography, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getValueFromLang } from "../../utils/utilities";
import { setServices } from "../../redux/ducks/Services";

const ProductDetail = (props) => {
  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  const getValue = (item) => {
    let productType = props.product;
    let result;
    if (props.product === "TravelInsurance") {
      props.productsDataDuck.productsData.healthtravelData.data.components.map(
        (component) => {
          if (item === component.id) {
            if (item === "iconImage" || item === "hero") {
              return (result = component.value);
            }
            return (result = getValueFromLang(
              component.translations,
              props.languageDuck.currentLanguage,
              true
            ));
          }
        }
      );
    }

    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === productType) {
        if (item === "iconImage" || item === "bannerImage") {
          return (result = product[item]);
        }
        result = getValueFromLang(
          product[item],
          props.languageDuck.currentLanguage
        );
      }
    });
    return result;
  };

  const available = () => {
    let available = false;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === props.product && product.b2c) {
        available = true;
      }
    });
    return available;
  };

  const getPrice = () => {
    let price;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === props.product) {
        price = product.prices;
      }
    });
    return price;
  };

  const addProduct = () => {
    let isPresent = false;
    let servicesObj = props.servicesDuck.services;
    servicesObj.map((service) => {
      if (service.name === props.product) {
        service.value += 1;
        isPresent = true;
      }
    });
    if (!isPresent) {
      servicesObj.push({
        name: props.product,
        value: 1,
        prices: getPrice(),
      });
    }

    notification.open({
      message: t("product.productAdded"),
      top: 120,
      duration: 3,
      className: "product-added-notification-message",
    });

    dispatch(setServices(servicesObj));
  };

  const goToTerms = () => {
    let termsLink = "";
    termsLink =
      props.product === "TravelInsurance"
        ? props.productsDataDuck.productsData.healthtravelData.data.components.find(
            (c) => c.id === "termConditionLink"
          )?.value
        : props.productsListDuck.productsList.data.find(
            (product) => product.code === props.product
          )?.termConditionLink;
    window.open(termsLink, "_blank");
  };

  const getHelmetValue = (value) => {
    let page;
    let url;

    switch (props.product) {
      // case "TravelInsurance":
      //   page = "ProductDetail_TravelInsurance";
      //   url = "travel-insurance";
      //   break;
      case "LLC":
        page = "ProductDetail_LLC";
        url = "LLC-detail";
        break;
      case "VIPLOUNGE":
        page = "ProductDetail_VIPLOUNGE";
        url = "VIPLOUNGE-detail";
        break;
      case "TLDRFM":
        page = "ProductDetail_DrTravel";
        url = "DrTravel-detail";
        break;
    }

    if (value === "page") {
      return page;
    }

    return url;
  };

  return (
    <Col
      className={
        props.product === "TravelInsurance"
          ? "product-header-container product-header-container-travel-insurance"
          : "product-header-container"
      }
      style={
        props.product === "TravelInsurance"
          ? {
              backgroundImage: `url(${getValue("hero")})`,
            }
          : {
              backgroundImage: `url(${getValue("bannerImage")})`,
              height: "auto",
            }
      }
    >
      <SEO page={getHelmetValue("page")} url={getHelmetValue("url")} />

      <div className={"home-header-image-mask"} />
      <Col className={"product-detail-container"}>
        <div className={"product-detail-logo-container"}>
          <Image src={getValue("iconImage")} alt={"icon"} preview={false} />
        </div>

        <Row className={"product-detail-title-container"}>
          <Title className={"product-detail-title"}>{getValue("name")}</Title>
          {props.product !== "TravelInsurance" && available() && (
            <CustomButton
              type={"primary"}
              content={t(`product.products.LLC.buttonContent`)}
              clickCallback={addProduct}
            />
          )}
        </Row>

        <div className={"product-detail-title-line"} />

        <div className={"product-detail-subtitle-container"}>
          <Text className={"product-detail-subtitle"}>
            {getValue("subtitle")}
          </Text>
        </div>
        <div className={"product-detail-description"}>
          {getValue("description")}
        </div>

        <Col className={"product-detail-carousel-container"}>
          <HowToCarousel productDetail={props.product} />

          <Text className={"product-detail-carousel-terms"}>
            {t("general.view")}{" "}
            <span onClick={goToTerms}>{t("general.terms")}</span>
          </Text>
        </Col>

        {/* {props.product === "TravelInsurance" && <TravelInsuranceForm />} */}
      </Col>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  productsListDuck: state.productsListDuck,
  productsDataDuck: state.productsDataDuck,
  languageDuck: state.languageDuck,
  servicesDuck: state.servicesDuck,
});

export default connect(mapStateToProps)(ProductDetail);
