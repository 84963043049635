import React from "react";

// STYLES
import './ProfileToggle.css';

import { Col, Switch, Typography } from "antd";

const ProfileToggle = (props) => {

  const { Text, Title } = Typography;

  return (
    <Col className={"profile-toggle-container"}>
      <Switch
        disabled={props.disabled}
        defaultChecked={props.defaultChecked}
        className={"profile-toggle"}
        onChange={props.onChange}
      />
      <Title className={"profile-toggle-title"}>{props.title}</Title>
      <Text className={"profile-toggle-subtitle"}>{props.description}</Text>
    </Col>
  )
}

export default ProfileToggle