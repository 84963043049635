const SET_AIRLINES = 'SET_AIRLINES'
const INIT_AIRLINES = 'INIT_AIRLINES'

export function setAirlines(value) {
  return {
    type: SET_AIRLINES,
    payload: {
      airlines: value
    }
  };
}

export function initAirlines() {
  return {
    type: INIT_AIRLINES,
    payload: {
      airlines: {}
    }
  }
}

const INIT_STATE = {
  airlines: {}
}

export default function airlinesDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_AIRLINES:
      newState.airlines = action.payload.airlines;
      break;
    case INIT_AIRLINES:
      newState.airlines = {};
      break;
    default:
      break;
  }
  return newState;
}