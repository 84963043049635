import React from "react";

// ASSESTS
import Cart from "../../../assets/images/home/cart-logo.svg";
import CartWhite from "../../../assets/images/home/cart-logo-white.svg";

// REDUX
import { connect, useDispatch } from "react-redux";
import { setCart } from "../../../redux/ducks/Cart";

import { Image, Col } from "antd";

const CartLink = (props) => {

  const dispatch = useDispatch();

  const cartValue = () => {
    let value = 0;
    let localServices = JSON.parse(localStorage.getItem('services'));

    if (localServices) {
      localServices.map((service) => {
        value = value + service.value;
      })
    }
    return value;
  }

  const handleClick = () => {
    dispatch(setCart(!props.cartDuck.cart));
  }

  return (
    <Col className={'mobile-navbar-button-container'}>
      <Col
        onClick={handleClick}
        className={'mobile-navbar-button-link'}
      >
        <Image
          src={props.white ? CartWhite : Cart}
          alt={'Cart'}
          preview={false}
        />
        <Col className={'mobile-navbar-cart-number'}>{cartValue()}</Col>
      </Col>
      <Col className={'mobile-navbar-button-mask'} />
    </Col>
  )
}

const mapStateToProps = state => ({
  servicesDuck: state.servicesDuck,
  cartDuck: state.cartDuck
})

export default connect(mapStateToProps)(CartLink)