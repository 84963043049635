import React, { useEffect, useState, useRef } from "react";

// STYLES
import './HeaderHome.css';

// ASSETS
import logo from '../../../assets/images/logo/logo-sos-travel-white-image.svg';
import logoDark from '../../../assets/images/logo/logo-sos-travel-gray.svg';
import Globe from '../../../assets/images/home/header-globe.svg';
import GlobeDark from '../../../assets/images/home/header-globe-gray.svg';

// COMPONENTS
import ProfileDropdownMenu from "../profileDropdownMenu/ProfileDropdownMenu";
import LangModal from "../../hooks_components/langModal/LangModal";
import CartLink from "../cartLink/CartLink";

// REDUX
import { connect, useDispatch } from "react-redux";
import { setCart } from "../../../redux/ducks/Cart";

import { ENVIRONMENT } from "../../../utils/properties";
import { Row, Typography, Image, Col } from "antd";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";


const HeaderHome = (props) => {

	const prevScrollY = useRef(0);
	const { Link, Text } = Typography;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [state, setState] = useState({
		isModalVisible: false,
		sticky: false
	})

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			if (currentScrollY > 0) {
				setState({
					...state,
					sticky: true
				})
			}
			if (currentScrollY === 0) {
				setState({
					...state,
					sticky: false
				})
			}
			prevScrollY.current = currentScrollY;
		};

		window.addEventListener("scroll", handleScroll, { passive: true });

		return () => window.removeEventListener("scroll", handleScroll);
	}, [state.sticky]);



	const handleBusinessAreaClick = () => {
		navigate(`${ENVIRONMENT.ROUTING.BASE_URL}B2B/home`);
	}

	const showModal = () => {
		setState({
			...state,
			isModalVisible: true
		})
	}

	const handleCancel = () => {
		setState({
			...state,
			isModalVisible: false
		})
	}

	const closeSiderMenu = () => {
		dispatch(setCart(false))
	}

	const headerClassName = () => {
		let className;
		if (state.sticky && props.cartDuck.cart) {
			className = 'home-header-container cart-sider-menu-header-container';
		} else if (state.sticky && !props.cartDuck.cart) {
			className = 'home-header-container home-header-sticky';
		} else if (!state.sticky && props.cartDuck.cart) {
			className = 'home-header-container cart-sider-menu-header-container';
		} else {
			className = 'home-header-container';
		}

		return className;
	}

	const usertype = () => {
		let userType = "B2C";
		if (props.userInfoDuck.userInfo && !isEmpty(props.userInfoDuck.userInfo)) {
			if (props.userInfoDuck.userInfo.type !== "B2C") {
				userType = "B2B";
			}
		}
		return userType;
	}

	const getHomeLink = () => {
		let userType = usertype();

		let link = `${ENVIRONMENT.ROUTING.BASE_URL}`;

		if (userType !== "B2C") {
			link = `${ENVIRONMENT.ROUTING.BASE_URL}B2B/home`;
		}

		return link
	}

	return (
		<>
			<Row
				className={headerClassName()}
			>
				<Link href={getHomeLink()} className={props.productDetail ? 'hidden-logo-mobile' : 'login-header-logo-container'}>
					<Image
						src={props.dark || (state.sticky && !props.cartDuck.cart) ? logoDark : logo}
						alt={'logo'}
						className={'login-header-logo'}
						preview={false}
					/>
				</Link>

				<Row className="home-header-buttons-container">
					{
						(!props.checkout || props.faqsPage) &&
						<>
							{
								usertype() === "B2C" &&
								<Text
									onClick={handleBusinessAreaClick}
									className={'home-header-area-business-link'}
									style={props.cartDuck.cart || props.dark || state.sticky ? { color: '#777679' } : null}
								>
									{t('header.businessArea')}
								</Text>
							}

							<Link onClick={showModal} className={'home-header-button-lang'}>
								<Image
									src={props.cartDuck.cart || props.dark || state.sticky ? GlobeDark : Globe}
									alt={'lang'}
									preview={false}
								/>
							</Link>

							<LangModal
								visible={state.isModalVisible}
								handleCancel={handleCancel}
							/>

							<CartLink
								white={props.cartDuck.cart || state.sticky ? false : !props.dark}
							/>
						</>
					}

					<ProfileDropdownMenu
						dark={props.dark}
					/>
				</Row>
			</Row>

			{
				props.cartDuck.cart &&
				<>
					<Row className={"home-header-container-consistency"} />
					<Col className={"cart-sider-menu-bg"} onClick={closeSiderMenu}>
						<Col className={"cart-sider-menu-bg-content-container"}>
							<div className={"cart-sider-menu-bg-text-container"}>{t("siderMenu.continueShopping")}</div>
							<div className={"cart-sider-menu-bg-text-underline"} />
						</Col>
					</Col>
				</>
			}
		</>
	)
}

const mapStateToProps = state => ({
	cartDuck: state.cartDuck,
	userInfoDuck: state.userInfoDuck
})

export default connect(mapStateToProps)(HeaderHome)