import Api from "../../services/Api";
import { setUserInfo } from "../ducks/UserInfo";
import { get } from 'lodash';
import { showError } from "../../utils/utilities";
import { setFlights } from "../ducks/Flights";
import { setAirports } from "../ducks/Airports";
import { setUserData } from "../ducks/UserData";
import { setLounge } from "../ducks/Lounge";
import { setPaymentToken } from "../ducks/PaymentToken";
import { setProductsList } from "../ducks/ProductsList";
import { setAirlines } from "../ducks/Airlines";
import { setHomeData } from "../ducks/HomeData";
import { setNewsletterData } from "../ducks/NewsletterData";
import { setProductsData } from "../ducks/ProductsData";
import { setFaqs } from '../ducks/Faqs';

export const doLogin = async (dispatch, data, type, errorMessage) => {
  try {
    const userInfo = await Api.login(data);
    if (!userInfo.error) {
      if ((userInfo.data.type === "B2C" && type === "B2C") || (userInfo.data.type !== "B2C" && type === "B2B")) {
        dispatch(setUserInfo(userInfo.data));
      } else {
        return showError(errorMessage);
      }
    } else {
      showError(get(userInfo, 'error.messageCode', 'ER000'));
    }
    return userInfo;
  } catch (err) {
    console.log('Error!', err);
  }
}

export const doLoginPhone = async (dispatch, data) => {
  try {
    const userInfo = await Api.loginPhone(data);

    if (!userInfo.error) {
      dispatch(setUserInfo(userInfo.data));
    } else {
      showError(get(userInfo, 'error.messageCode', 'ER000'));
    }
    return userInfo;
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const doSignup = async (dispatch, data) => {
  try {
    const userInfo = await Api.signup(data);

    if (!userInfo.error) {
      // dispatch(setUserInfo(userInfo.data));
      // dispatch(setLanguage('GB')); // set default language
    } else {
      showError(get(userInfo, 'error.messageCode', 'ER000'));
    }
    return userInfo;
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const sendOTP = async (dispatch, data) => {
  try {
    const OTP = await Api.OTP(data);
    if (!OTP.error) {
      dispatch(setUserInfo(OTP.data));
    } else {
      showError(get(OTP, 'error.messageCode', 'ER000'));
    }
    return OTP;
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const doUpdateData = async (data) => {
  try {
    const updateData = await Api.updateData(data);
    if (!updateData.error) {
      return updateData;
    } else {
      showError(get(updateData, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const doForgotPsw = async (data) => {
  try {
    const forgotPsw = await Api.forgotPsw(data);
    if (!forgotPsw.error) {
      return forgotPsw;
    } else {
      showError(get(forgotPsw, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const confirmResetPsw = async (dispatch, data) => {
  try {
    const confirmNewPsw = await Api.resetPsw(data);
    if (!confirmNewPsw.error) {
      dispatch(setUserInfo(confirmNewPsw.data));

    } else {
      showError(get(confirmNewPsw, 'error.messageCode', 'ER000'));
    }
    return confirmNewPsw;
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const doSearchFlights = async (dispatch, data) => {
  try {
    const searchFlights = await Api.searchFlight(data);
    if (!searchFlights.error) {
      dispatch(setFlights(searchFlights));
    } else {
      showError(get(searchFlights, 'error.messageCode', 'ER000'));
    }
    return searchFlights;
  } catch (err) {
    // console.log(err)
  }
}

export const doSearchAirports = async (dispatch, data) => {
  try {
    const searchAirports = await Api.searchAirports(data);
    if (!searchAirports.error) {
      dispatch(setAirports(searchAirports));
    } else {
      showError(get(searchAirports, 'error.messageCode', 'ER000'));
    }
    return searchAirports;
  } catch (err) {
    // console.log(err)
  }
}

export const doSearchAirlines = async (dispatch) => {
  try {
    const searchAirlines = await Api.searchAirlines();
    if (!searchAirlines.error) {
      dispatch(setAirlines(searchAirlines));
    } else {
      showError(get(searchAirlines, 'error.messageCode', 'ER000'));
    }
    return searchAirlines;
  } catch (err) {
    // console.log(err)
  }
}

export const doSearchLounge = async (dispatch, data) => {
  try {
    const searchLounge = await Api.searchLounge(data);
    if (!searchLounge.error) {
      dispatch(setLounge(searchLounge));
    } else {
      showError(get(searchLounge, 'error.messageCode', 'ER000'));
    }
    return searchLounge;
  } catch (err) {
    // console.log(err)
  }
}

export const getHomeData = async (dispatch, data) => {
  try {
    const homeData = await Api.pageData(data);
    if (!homeData.error) {
      dispatch(setHomeData(homeData));
    } else {
      showError(get(homeData, 'error.messageCode', 'ER000'));
    }
    return homeData;
  } catch (err) {
    // console.log(err)
  }
}

export const getNewsletterData = async (dispatch, data) => {
  try {
    const newsletterData = await Api.pageData(data);
    if (!newsletterData.error) {
      dispatch(setNewsletterData(newsletterData));
    } else {
      showError(get(newsletterData, 'error.messageCode', 'ER000'));
    }
    return newsletterData;
  } catch (err) {
    // console.log(err)
  }
}

export const getPagesData = async (dispatch) => {
  try {
    const pagesData = {
      llcData: await Api.pageData("llc"),
      viploungeData: await Api.pageData("viplounge"),
      drtravelData: await Api.pageData("drtravel"),
      drtravelfamData: await Api.pageData("drtravelfam"),
      healthtravelData: await Api.pageData("healthtravel")
    }

    if (!pagesData.error) {
      dispatch(setProductsData(pagesData));
    } else {
      showError(get(pagesData, 'error.messageCode', 'ER000'));
    }
    return pagesData;
  } catch (err) {
    // console.log(err)
  }
}

export const getFaqsData = async (dispatch) => {
  try {
    const faqsData = await Api.pageData("faq")

    if (!faqsData.error) {
      dispatch(setFaqs(faqsData));
    } else {
      showError(get(faqsData, 'error.messageCode', 'ER000'));
    }
    return faqsData;
  } catch (err) {
    // console.log(err)
  }
}

export const getUserData = async (dispatch) => {
  try {
    const userData = await Api.userData();
    if (!userData.error) {
      dispatch(setUserData(userData));
    } else {
      showError(get(userData, 'error.messageCode', 'ER000'));
    }
    return userData;
  } catch (err) {
    // console.log(err)
  }
}

export const getOrders = async (data) => {
  try {
    const userOrders = await Api.userOrders(data);

    if (!userOrders.error) {
      return userOrders;
    } else {
      showError(get(userOrders, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log(err)
  }
}

export const getPromo = async () => {
  let excludeExpired = { "excludeExpired": true }
  try {
    const userPromo = await Api.userPromo(excludeExpired);

    if (!userPromo.error) {
      return userPromo;
    } else {
      showError(get(userPromo, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log(err)
  }
}

export const getPaymentToken = async (dispatch, data) => {
  try {
    const paymentToken = await Api.paymentToken(data);
    if (!paymentToken.error) {
      dispatch(setPaymentToken(paymentToken));
    } else {
      showError(get(paymentToken, 'error.messageCode', 'ER000'));
    }
    return paymentToken;
  } catch (err) {
    // console.log(err)
  }
}

export const doPayment = async (data) => {
  try {
    const payment = await Api.payment(data);
    if (!payment.error) {
      // dispatch(initServices());
      return payment;
    } else {
      showError(get(payment, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const getProductsList = async (dispatch, isB2B) => {
  try {
    const productsList = await Api.productsList(isB2B);
    if (!productsList.error) {
      dispatch(setProductsList(productsList));
    } else {
      showError(get(productsList, 'error.messageCode', 'ER000'));
    }
    return productsList;
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const b2bGetPaymentMethods = async () => {
  try {
    const paymentMethods = await Api.b2bPaymentMethods();
    if (!paymentMethods.error) {
      return paymentMethods;
    } else {
      showError(get(paymentMethods, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const addPaymentMethodCard = async (nonce) => {
  try {
    const addPayment = await Api.addCreditCard(nonce);
    if (!addPayment.error) {
      return addPayment;
    } else {
      showError(get(addPayment, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const removePaymentMethodCard = async (token) => {
  try {
    const removePayment = await Api.deleteCreditCard(token);
    if (!removePayment.error) {
      return removePayment;
    } else {
      showError(get(removePayment, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const activatePromotion = async (data) => {
  try {
    const activate = await Api.activatePromo(data);
    if (!activate.error) {
      return activate;
    } else {
      showError(get(activate, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const calculatePromotion = async (data) => {
  try {
    const calculate = await Api.calculatePromo(data);
    if (!calculate.error) {
      return calculate;
    } else {
      showError(get(calculate, 'error.messageCode', 'ER000'));
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const getDownloadReceipt = async (data, errorMessage) => {
  try {
    const download = await Api.downloadReceipt(data);
    if (download.type !== "application/json") {
      const url = window.URL.createObjectURL(new Blob([download]));
      const link = document.createElement("a");
      link.href = url;
      link.style.visibility = "hidden";
      link.setAttribute("download", `order_${data}.pdf`);
      document.body.appendChild(link);
      link.click();
    } else {
      showError(errorMessage);
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}

export const getDownloadItemReceipt = async (data, item, errorMessage) => {
  try {
    const download = await Api.downloadItemReceipt(data, item);
    if (download.type !== "application/json") {
      const url = window.URL.createObjectURL(new Blob([download]));
      const link = document.createElement("a");
      link.href = url;
      link.style.visibility = "hidden";
      link.setAttribute("download", `${item}_order_${data}.pdf`);
      document.body.appendChild(link);
      link.click();
    } else {
      showError(errorMessage);
    }
  } catch (err) {
    // console.log('Error!', err);
  }
}