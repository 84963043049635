import React from "react";

// STYLES
import "./ProfileDataItem.css";

// COMPONENTS
import PrefixPhoneMenu from "../../hooks_components/prefixPhoneMenu/PrefixPhoneMenu";

import { Col, Typography, Input, Row } from "antd";

const ProfileDataItem = (props) => {
  const { Text } = Typography;

  const prefixHandleChange = (value, prefixPhoneCountry) => {
    props.prefixHandleChange(value, prefixPhoneCountry);
  };

  const handleChange = (e) => {
    props.callback(e);
  };

  const checkNumber = (e) => {
    if (props.prefix) {
      if ((e.which != 8 && e.which != 0 && e.which < 48) || e.which > 57) {
        e.preventDefault();
      }
    }
  };

  return (
    <Col className={"profile-data-item-container"}>
      <Text>{props.label}</Text>
      <Row>
        {props.prefix && (
          <PrefixPhoneMenu
            handleChangeCallback={prefixHandleChange}
            disabled={props.disabled}
            profile
          />
        )}
        <Input
          id={props.id}
          placeholder={props.value}
          onChange={handleChange}
          onKeyPress={checkNumber}
          className={
            props.prefix
              ? "profile-data-item-input profile-data-item-input-phone"
              : "profile-data-item-input"
          }
          disabled={props.disabled}
          style={props.style}
          autoComplete={"off"}
          type={props.prefix ? "number" : "string"}
        />
      </Row>
    </Col>
  );
};

ProfileDataItem.defaultProps = {
  label: "Label",
  value: "-",
  disabled: true,
  prefix: false,
};

export default ProfileDataItem;
