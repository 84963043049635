import React from "react";

// STYLES
import './HowToCarouselCard.css';

import { Typography, Image } from "antd";

const HowToCarouselCard = (props) => {

  const { Text } = Typography;

  return (
    <div className={"how-to-carousel-step-container"}>
      <div className={"how-to-carousel-step-image-container"}>
        <Image
          alt={props.step}
          src={props.src}
          preview={false}
          className={"how-to-carousel-step-image"}
        />
      </div>

      <Text className={props.travelInsurance ? "how-to-carousel-step-title how-to-carousel-step-title-insurance" : "how-to-carousel-step-title"} style={props.whiteTitle ? { color: '#FFFFFF' } : {}}>
        {props.step}
      </Text>

      <div className={"how-to-carousel-step-line-separator"} />

      <Text className={"how-to-carousel-step-description"}>
        {props.description}
      </Text>
    </div>
  )
}

export default HowToCarouselCard