import React, { useEffect, useState } from "react";

// STYLES
import "./CheckOutLayout.css";

// COMPONENTS
import CheckoutHeader from "../../components/functional_components/checkoutHeader/CheckoutHeader";
import MobileNavbar from "../../components/hooks_components/mobileNavbar/MobileNavbar";
import Loading from "../../screens/Loading/Loading";

// REDUX
import { getUserData, getProductsList } from "../../redux/actions/actions";
import { useDispatch, connect } from "react-redux";

import { Layout } from "antd";
import { Outlet } from "react-router";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

const CheckOutLayout = (props) => {
  const { Header, Content } = Layout;
  const dispatch = useDispatch();

  const [state, setState] = useState({
    loading: true,
  });

  useEffect(() => {
    productsList();
  }, []);

  const productsList = async () => {
    if (Object.keys(props.productsListDuck.productsList).length === 0) {
      let userInfo = JSON.parse(CryptoAES.decrypt(localStorage.getItem('userInfo'), "MEGAKEY").toString(CryptoENC));
      
      let isB2B = !userInfo.type.includes("B2C");
      await getProductsList(dispatch, isB2B);
    }

    if ((Object.keys(props.userDataDuck.userData).length === 0) && props.step !== 1) {
      await getUserData(dispatch);
    }

    setState({
      ...state,
      loading: false,
    });
  };

  return (
    <div>
      <Layout className={"checkout-layout-container"}>
        {state.loading && <Loading />}
        {!state.loading && (
          <>
            <Header className={"checkout-layout-header-container"}>
              <CheckoutHeader step={props.step} />
            </Header>

            <Content>
              <Outlet />
            </Content>

            <MobileNavbar checkout />
          </>
        )}
      </Layout>
    </div>
  );
};

CheckOutLayout.defaultProps = {
  step: 1,
};

const mapStateToProps = (state) => ({
  productsListDuck: state.productsListDuck,
  userDataDuck: state.userDataDuck,
});

export default connect(mapStateToProps)(CheckOutLayout);
