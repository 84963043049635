import React from "react";

// STYLES
import './ErrorPage.css';

// COMPONENTS
import HeaderHome from "../../components/functional_components/headerHome/HeaderHome";
import MobileNavbar from "../../components/hooks_components/mobileNavbar/MobileNavbar";

// ASSETS
import Astronaut from "../../assets/images/error/error-page-image.svg";

import { Col, Image, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../utils/properties";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {

  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backToHome = () => {
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}`)
  }

  return (
    <Col>
      <Row className={"thank-you-page-header-container"}>
        <HeaderHome dark />
      </Row>

      <Col className={"error-page-content-container"}>
        <div className={"error-page-image"}>
          <Image
            src={Astronaut}
            alt={'error'}
            preview={false}
          />
        </div>

        <Col className={"error-page-content-text-container"}>
          <Title>{t("errorPage.title")}</Title>
          <Text>{t("errorPage.description")}</Text>
          <div className={"error-page-content-text-report-container"}>
            <Text><span onClick={backToHome}>{t("404page.report")}</span> {t("general.and")} <span onClick={backToHome}>{t("404page.backToHome")}</span>.</Text>
          </div>
        </Col>

      </Col>

      <MobileNavbar />
    </Col>
  )
}

export default ErrorPage