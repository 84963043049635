const SET_USERDATA = 'SET_USERDATA'
const INIT_USERDATA = 'INIT_USERDATA'

export function setUserData(value) {
  return {
    type: SET_USERDATA,
    payload: {
      userData: value
    }
  };
}

export function initUserData() {
  return {
    type: INIT_USERDATA,
    payload: {
      userData: false
    }
  }
}

const INIT_STATE = {
  userData: false
}

export default function userDataDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_USERDATA:
      newState.userData = action.payload.userData;
      break;
    case INIT_USERDATA:
      newState.userData = false;
      break;
    default:
      break;
  }
  return newState;
}
