import React from "react";

// STYLES 
import './HomeServiceCard.css';

// COMPONENTS
import CustomButton from '../customButton/CustomButton';

import { Typography, Col } from "antd";

const HomeServiceCard = (props) => {

  const { Title, Text } = Typography;

  return (
    <Col className={props.reverse ? 'home-service-card-container card-reverse' : 'home-service-card-container'}>
      <Col className={'home-service-card-image-container'}>
        <div className={'home-service-card-image-mark-light'} />
        <div className={'home-service-card-image-mark-dark'} />
        <div
          className={'home-service-card-image'}
          style={{ backgroundImage: `url(${props.src}` }}
        />
      </Col>
      <Col className={'home-service-text-container'}>
        <Title className={'home-service-title'}>{props.title}</Title>
        <Text className={'home-service-description'}>{props.description}</Text>
        <CustomButton
          type={'primary-lg'}
          content={props.content}
          href={props.link}
        />
      </Col>
    </Col>
  )
}

export default HomeServiceCard;