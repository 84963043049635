import React, { useEffect, useState } from "react";

// STYLES
import "./Payment.css";

// REDUX
import { doPayment } from "../../../redux/actions/actions";
import { connect, useDispatch } from "react-redux";

import dropin from "braintree-web-drop-in";
import { Button } from "antd";
import { get } from "lodash";
import { showError } from "../../../utils/utilities";
import { useTranslation } from "react-i18next";

const BraintreeDropIn = (props) => {
  const { show, onPaymentCompleted, onPaymentFailed, removeLoading } = props;

  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const [tot, setTot] = useState(0);

  const { t, i18n } = useTranslation();

  const localeMap = {
    it: "it_IT",
    en: "en_US",
    fr: "fr_FR",
    es: "es_ES",
    pt: "pt_PT",
    ru: "ru_RU",
  };

  let paymentMethodNonce = "";
  // const dispatch = useDispatch();

  const setLoading = () => {
    props.setLoading();
  };

  useEffect(() => {
    let newTot = 0;
    props.servicesDuck.services.map((service) => {
      let servicePrice;
      service.prices.map((price) => {
        if (price.currency === props.currencyDuck.currency) {
          servicePrice = price.price;
        }
      });
      newTot = newTot + service.value * servicePrice;
    });
    setTot(newTot.toFixed(2));
  }, []);

  useEffect(() => {
    if (
      tot &&
      show &&
      get(props.paymentTokenDuck.paymentToken, "data.token", false)
    ) {
      const initializeBraintree = () =>
        dropin.create(
          {
            // insert your tokenization key or client token here
            authorization: props.paymentTokenDuck.paymentToken.data.token,
            container: "#braintree-drop-in-div",
            threeDSecure: true,
            locale: localeMap[i18n.resolvedLanguage],
            paypal: {
              flow: "checkout",
              amount: props.discount.code ? props.discount.amount : tot,
              currency: props.currencyDuck.currency,
            },
          },
          function (error, instance) {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
          }
        );
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [tot, show]);

  const handlePayment = async () => {
    let orders = [];

    const getServiceId = (service) => {
      let id;
      props.productsListDuck.productsList.data.map((product) => {
        if (product.code === service.name) {
          id = product.id;
        }
      });
      return id;
    };

    const ordersPush = () => {
      props.servicesDuck.services.map((service) => {
        let order = {
          productCode: service.name,
          productId: getServiceId(service),
          quantity: service.value,
        };

        if (service.name !== "LLC") {
          order = {
            productCode: service.name,
            productId: getServiceId(service),
            quantity: service.value,
            data: service.data,
          };
        }

        orders.push(order);
      });
    };

    ordersPush();

    let order = {
      currency: props.currencyDuck.currency,
      items: orders,
      paymentNonce: paymentMethodNonce,
      customerName: props.userData.name ? props.userData.name : null,
      customerSurname: props.userData.surname ? props.userData.surname : null,
      invoice: props.userData.invoice ? props.userData.invoice : false,
      cdfOrPiva: props.userData.cdfOrPiva ? props.userData.cdfOrPiva : "",
      sdi: props.userData.sdi ? props.userData.sdi : "",
    };

    if (props.discount.code) {
      order.promotionCode = props.discount.code;
    }

    let paymentResponse = await doPayment(order);

    if (paymentResponse.error) {
      return showError(paymentResponse.error.messageCode);
    }

    if (paymentResponse.data.status === "ERROR"
        || paymentResponse.data.status === "CANCELED") {
      return onPaymentFailed();
    }

    onPaymentCompleted();
  };

  return (
    <div style={{ display: `${show ? "block" : "none"}` }}>
      <div id={"braintree-drop-in-div"} />
      {braintreeInstance && (
        <>
          <Button
            className={"braintreePayButton"}
            type="primary"
            disabled={!braintreeInstance}
            onClick={() => {
              if (braintreeInstance) {
                braintreeInstance.requestPaymentMethod(
                  {
                    threeDSecure: {
                      amount: props.discount.code ? props.discount.amount : tot,
                    },
                  },
                  (error, payload) => {
                    if (error) {
                      console.error(error);
                    } else {
                      // if (payload.threeDSecureInfo.authentication.transStatus) {
                      setLoading();
                      paymentMethodNonce = payload.nonce;
                      return handlePayment();
                      // }
                      // removeLoading();
                      // braintreeInstance.clearSelectedPaymentMethod();
                    }
                  }
                );
              }
            }}
          >
            {t("checkout.pay")}
          </Button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  paymentTokenDuck: state.paymentTokenDuck,
  productsListDuck: state.productsListDuck,
  servicesDuck: state.servicesDuck,
  currencyDuck: state.currencyDuck,
});

export default connect(mapStateToProps)(BraintreeDropIn);
