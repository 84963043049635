import React, { useState, useEffect } from "react";

// COMPONENTS
import PaymentMethodCard from "../../components/functional_components/paymentMethodCard/PaymentMethodCard";

import { Col, Row, Typography, Button, Modal, Skeleton } from "antd";
import { getPaymentToken, b2bGetPaymentMethods, addPaymentMethodCard, removePaymentMethodCard } from "../../redux/actions/actions";
import { useTranslation } from "react-i18next";
import dropin from "braintree-web-drop-in";
import { connect, useDispatch } from "react-redux";

const B2B_PaymentMethods = (props) => {

  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [braintreeInstance, setBraintreeInstance] = useState(undefined)

  const [state, setState] = useState({
    loading: true,
    paymentMethods: [],
    addCardModal: false,
    removeCardModal: false,
    modalLoading: false,
    methodClicked: null
  })

  useEffect(() => {
    getCards();
  }, [state.loading])

  const getCards = async () => {
    let result = await b2bGetPaymentMethods();
    if (result) {
      setState({
        ...state,
        paymentMethods: result.data,
        loading: false
      })
    }
  }

  const deletePaymentMethod = (method) => () => {
    setState({
      ...state,
      removeCardModal: true,
      methodClicked: method.token
    })
  }

  const confirmDeletePaymentMethod = async () => {
    setState({
      ...state,
      modalLoading: true
    })
    await removePaymentMethodCard({
      paymentMethodCode: state.methodClicked
    });
    closeModal("reload");
  }

  const addPaymentMethod = () => {
    braintreeAddCard();
    setState({
      ...state,
      addCardModal: true
    })
  }

  const closeModal = (value) => {
    setState({
      ...state,
      addCardModal: false,
      removeCardModal: false,
      modalLoading: false,
      loading: value === "reload"
    })
  }

  const braintreeAddCard = async () => {
    let currency = props.currencyDuck.currency
    let token = await getPaymentToken(dispatch, currency);

    if (token) {
      const initializeBraintree = () => dropin.create({
        authorization: token.data.token,
        container: '#braintree-drop-in-div',
      }, function (error, instance) {
        if (error)
          console.error(error)
        else
          setBraintreeInstance(instance);
      });
      if (braintreeInstance) {
        braintreeInstance
          .teardown()
          .then(() => {
            initializeBraintree();
          });
      } else {
        initializeBraintree();
      }
    }
  }

  return (
    <Col className={"profile-account-container"}>

      <Modal visible={state.addCardModal} onCancel={closeModal} onOk={closeModal} footer={null} destroyOnClose>
        <div
          id={"braintree-drop-in-div"}
        />
        <Button
          className={"braintreePayButton"}
          type="primary"
          disabled={!braintreeInstance}
          onClick={() => {
            if (braintreeInstance) {
              braintreeInstance.requestPaymentMethod({}, async (error, payload) => {
                if (error) {
                  console.error(error);
                } else {
                  await addPaymentMethodCard({
                    paymentMethodCode: payload.nonce
                  });
                  closeModal("reload");
                }
              });
            }
          }}
        >
          {t("profile.addCard")}
        </Button>
      </Modal>
      <Modal visible={state.removeCardModal} onCancel={closeModal} onOk={confirmDeletePaymentMethod} okText={"Delete"} confirmLoading={state.modalLoading}>
        {t("profile.areYouSureToRemove")}
      </Modal>

      <Col className={'profile-account-title-desktop'}>
        <Title>{t("profile.paymentMethods")}</Title>
        <div className={"profile-account-title-desktop-line"} />
      </Col>
      {
        state.loading &&
        <Col>
          <Skeleton active />
          <Skeleton active />
        </Col>
      }
      {
        !state.loading &&
        <Col className={"profile-payment-methods-container"}>

          {
            state.paymentMethods.length > 0 &&
            <>
              <Text className={"profile-payment-methods-title"}>{t("profile.myPaymentMethods")}</Text>
              {
                state.paymentMethods.map((method, index) => {
                  return (
                    <div key={index}>
                      <PaymentMethodCard
                        src={method.imageUrl}
                        cardType={method.details.cardType}
                        last4={method.details.last4}
                        expiring={method.details.expirationDate}
                        delete
                        handleClick={deletePaymentMethod(method)}
                      />
                    </div>
                  )
                })
              }
            </>
          }
          <Text className={"profile-payment-methods-title"}>{t("profile.addPaymentMethod")}</Text>
          <Row className={"profile-add-payment-method-container"} onClick={addPaymentMethod}>
            <Text>{t("profile.addPaymentMethod")}</Text>
            <Button
              className={'checkout-service-card-footer-confirm-button'}
            >
              <span>+</span>
            </Button>
          </Row>
        </Col>
      }
    </Col>
  )
}

const mapStateToProps = state => ({
  currencyDuck: state.currencyDuck
})

export default connect(mapStateToProps)(B2B_PaymentMethods)