const SET_CURRENCY = 'SET_CURRENCY'
const INIT_CURRENCY = 'INIT_CURRENCY'

export function setCurrency(value) {
  return {
    type: SET_CURRENCY,
    payload: {
      currency: value
    }
  };
}

export function initCurrency() {
  return {
    type: INIT_CURRENCY,
    payload: {
      currency: "EUR"
    }
  }
}

const INIT_STATE = {
  currency: "EUR"
}

export default function currencyDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_CURRENCY:
      newState.currency = action.payload.currency;
      break;
    case INIT_CURRENCY:
      newState.currency = {};
      break;
    default:
      break;
  }
  return newState;
}