import React, { useState } from "react";

// STYLES
import '../SignIn/SignIn.css';

// COMPONENTS
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import ProviderButton from "../../components/functional_components/providerButton/ProviderButton";
import SEO from "../../components/functional_components/SEO/SEO";

// ASSETS
import FacebookIcon from "../../assets/images/social/logo-facebook.svg";
import GoogleIcon from "../../assets/images/social/logo-google.svg";
import AppleIcon from "../../assets/images/social/logo-apple.svg";
import HuaweiIcon from "../../assets/images/social/logo-huawei.svg";

// REDUX
import { useDispatch } from "react-redux";
import { doSignup } from '../../redux/actions/actions';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Input, Row, Col, Checkbox, Typography } from "antd";
import { ENVIRONMENT, emailValidation, passwordValidation } from '../../utils/properties';
import { providersURL } from "../../utils/utilities";
import { useNavigate, useLocation } from "react-router-dom";
import { get } from "lodash";

const SignUp = () => {

  const [state, setState] = useState({
    isLoading: false,
    acceptTerms: false,
    validEmail: true,
    validPassword: true,
    errorCheckbox: false
  });

  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const formikLogin = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: (values) => {
      sendSignup(values);
    }
  });


  const sendSignup = async (values) => {
    try {
      setState({
        ...state,
        isLoading: true
      });

      let dataSignup = {
        email: values.username,
        password: values.password,
        privacy: state.acceptTerms,
        source: "SOSTRAVEL"
      }

      if (!emailValidation(dataSignup.email)) {
        return (
          setState({
            ...state,
            validEmail: false
          })
        )
      }

      if (!passwordValidation(dataSignup.password)) {
        return (
          setState({
            ...state,
            validPassword: false
          })
        )
      }

      if (state.acceptTerms === false) {
        return (
          setState({
            ...state,
            errorCheckbox: true
          })
        )
      }

      const signupData = await doSignup(dispatch, dataSignup);
      if (!signupData.error) {
        navigate(`${ENVIRONMENT.ROUTING.SIGNUP_URL}/OTP`, { state: { email: values.username } });
      }

      setState({
        ...state,
        isLoading: false
      });
    } catch (err) {
      setState({
        ...state,
        isLoading: false
      });
    }
  };

  const onChangeCheckbox = () => {
    setState({
      ...state,
      acceptTerms: !state.acceptTerms,
      errorCheckbox: false
    })
  }

  const handleEmailInputFocus = () => {
    setState({
      ...state,
      validEmail: true
    })
  }

  const handlePasswordInputFocus = () => {
    setState({
      ...state,
      validPassword: true
    })
  }

  return (
    <div className={'login-screen-container'}>
      <SEO
        page={"SignUp"}
        url={"signup"}
      />

      <Row className={'login-title-container'}>
        <Title className={'login-title'}>{t('signup.title')}</Title>
      </Row>

      <Row className={'login-subtitle-container'}>
        <p className={'login-subtitle'}>{t('signup.subtitle')}</p>
      </Row>

      <Row>
        <label className='login-label'>{t('general.emailLabel')}</label>
        <Input
          id="username"
          name="username"
          placeholder={t('general.emailPlaceholder')}
          onChange={formikLogin.handleChange}
          onBlur={formikLogin.handleBlur}
          value={formikLogin.values.username}
          className={state.validEmail ? 'login-input-box' : 'login-input-box invalid-field'}
          onFocus={handleEmailInputFocus}
          autoComplete={"off"}
        />
        {
          !state.validEmail &&
          <Text className={'invalid-field-message'}>{t('general.invalidEmail')}</Text>
        }
      </Row>

      <Row>
        <label className='login-label'>{t('general.password')}</label>
        <Input.Password
          id="password"
          name="password"
          placeholder={t('general.passwordPlaceholder')}
          onChange={formikLogin.handleChange}
          onBlur={formikLogin.handleBlur}
          value={formikLogin.values.password}
          className={state.validPassword ? 'login-input-box' : 'login-input-box invalid-field'}
          onFocus={handlePasswordInputFocus}
        />
        {
          !state.validPassword &&
          <Text className={'invalid-field-message'}>{t('general.invalidPassword')}</Text>
        }
      </Row>

      <Row className={'login-terms-container'}>
        <Checkbox
          onChange={onChangeCheckbox}
          className={state.errorCheckbox ? 'terms-checkbox-error' : ''}
        />
        <p className={state.errorCheckbox ? 'login-terms-text terms-text-error' : 'login-terms-text'} dangerouslySetInnerHTML={{__html: t('signup.terms')}}/>
      </Row>

      <Row className={'login-submit-button-container'}>
        <CustomButton
          type="primary"
          isLoading={state.isLoading}
          clickCallback={formikLogin.submitForm}
          content={t('general.continue')}
        />
      </Row>

      <div className='login-provider-separator-container'>
        <div className='login-provider-separator-text'>
          {t('general.or')}
        </div>
        <div className='login-provider-separator-line' />
      </div>

      <Col className={'login-providers-buttons-container'}>
        <Col className={'login-providers-buttons-responsive-row'}>
          <ProviderButton
            label={`${t("signin.signInWith")} ${t("signin.social.Facebook")}`}
            href={providersURL(get(location.state, "from.pathname", null), 'facebook')}
            logo={FacebookIcon}
            fb
          />
          <ProviderButton
            label={`${t("signin.signInWith")} ${t("signin.social.Google")}`}
            href={providersURL(get(location.state, "from.pathname", null), 'google')}
            logo={GoogleIcon}
          />
        </Col>
        <Col className={'login-providers-buttons-responsive-row'}>
          <ProviderButton
            label={`${t("signin.signInWith")} ${t("signin.social.Apple")}`}
            href={providersURL(get(location.state, "from.pathname", null), 'apple')}
            logo={AppleIcon}
            apple
          />
          <ProviderButton
            label={`${t("signin.signInWith")} ${t("signin.social.Huawei")}`}
            href={providersURL(get(location.state, "from.pathname", null), 'huawei')}
            logo={HuaweiIcon}
          />
        </Col>
      </Col>
    </div>
  )
}

export default SignUp;
