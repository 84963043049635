import React, { useState } from "react";

// STYLES
import "../SignIn/SignIn.css";

// COMPONENTS
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import PrefixPhoneMenu from "../../components/hooks_components/prefixPhoneMenu/PrefixPhoneMenu";
import SEO from "../../components/functional_components/SEO/SEO";

// REDUX
import { doUpdateData } from "../../redux/actions/actions";
import { connect } from "react-redux";

import { Input, Row, Typography } from "antd";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { ENVIRONMENT, phoneValidation } from "../../utils/properties";
import { useNavigate } from "react-router-dom";

const SignUpStep2 = (props) => {
  const [state, setState] = useState({
    areacode: "+39",
    prefixPhoneCountry: "IT",
    prefixDisabled: false,
    validPhone: true,
    country: "",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Title, Text } = Typography;

  const formikLogin = useFormik({
    initialValues: {
      username: "",
      surname: "",
      phone: "",
      country: "",
    },
    onSubmit: (values) => {
      sendUpdateData(values);
    },
  });

  const handleSkip = () => {
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}`);
  };

  const sendUpdateData = async (values) => {
    try {
      let updateData = {
        country: `${state.country.toUpperCase()}`,
        name: values.username,
        surname: values.surname,
        prefixPhoneCountry: state.prefixPhoneCountry,
        prefixPhone: state.areacode,
        phone: values.phone,
        type: props.userInfoDuck.userInfo.type,
        id: props.userInfoDuck.userInfo.id,
        email: props.userInfoDuck.userInfo.email,
        username: props.userInfoDuck.userInfo.email,
        source: "SOSTRAVEL",
      };

      if (updateData.phone.length > 0 && !phoneValidation(updateData.phone)) {
        return setState({
          ...state,
          validPhone: false,
        });
      }

      const updateUserData = await doUpdateData(updateData);
      if (!updateUserData.error) {
        navigate(`${ENVIRONMENT.ROUTING.BASE_URL}`);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const prefixHandleChange = (value, prefixPhoneCountry) => {
    setState({
      ...state,
      areacode: value,
      prefixPhoneCountry,
    });
  };

  const handlePhoneInputFocus = () => {
    setState({
      ...state,
      prefixDisabled: true,
      validPhone: true,
    });
  };

  const handlePhoneInputBlur = () => {
    setState({
      ...state,
      prefixDisabled: false,
    });
  };

  const handleCountryChange = (value) => {
    setState({
      ...state,
      country: value,
    });
  };

  return (
    <div className={"login-screen-container"}>
      <SEO page={"SignUp_step2"} url={"signup/step2"} />

      <Row className={"login-title-container"}>
        <Title className={"login-title"}>{t("signup.page2.title")}</Title>
      </Row>

      <Row>
        <label className={"login-label"}>{t("general.name")}</label>
        <Input
          id={"username"}
          name={"username"}
          placeholder={t("general.name")}
          onChange={formikLogin.handleChange}
          onBlur={formikLogin.handleBlur}
          value={formikLogin.values.username}
          className={"login-input-box"}
          autoComplete={"off"}
        />
      </Row>

      <Row>
        <label className={"login-label"}>{t("general.surname")}</label>
        <Input
          id={"surname"}
          name={"surname"}
          placeholder={t("general.surname")}
          onChange={formikLogin.handleChange}
          onBlur={formikLogin.handleBlur}
          value={formikLogin.values.surname}
          className={"login-input-box"}
          autoComplete={"off"}
        />
      </Row>

      <Row>
        <label className={"login-label"}>{t("general.phone")}</label>
        <Row className={"login-input-phone-container"}>
          <PrefixPhoneMenu
            handleChangeCallback={prefixHandleChange}
            disabled={state.prefixDisabled}
          />
          <Input
            id="phone"
            name="phone"
            placeholder={"123456"}
            onChange={formikLogin.handleChange}
            value={formikLogin.values.phone}
            className={
              state.validPhone
                ? "login-input-box login-input-phone"
                : "login-input-box login-input-phone invalid-field"
            }
            onFocus={handlePhoneInputFocus}
            onBlur={handlePhoneInputBlur}
            autoComplete={"off"}
          />
          {!state.validPhone && (
            <Text className={"invalid-field-message"}>
              {t("general.invalidPhone")}
            </Text>
          )}
        </Row>
      </Row>

      <ReactFlagsSelect
        selected={state.country.toUpperCase()}
        onSelect={handleCountryChange}
        searchable
        selectedSize={14}
        optionsSize={14}
        fullWidth={false}
        className={"login-input-country-select"}
        placeholder={t("general.selectCountry")}
      />

      <Row className={"login-submit-button-container"}>
        <div className={"mr-20"}>
          <CustomButton
            type="primary-sm"
            clickCallback={formikLogin.submitForm}
            content={t("general.complete")}
          />
        </div>
        <CustomButton
          type="transparent-lg"
          clickCallback={handleSkip}
          content={t("general.skip")}
        />
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfoDuck: state.userInfoDuck,
});

export default connect(mapStateToProps)(SignUpStep2);
