const SET_FAQS = 'SET_FAQS'
const INIT_FAQS = 'INIT_FAQS'

export function setFaqs(value) {
  return {
    type: SET_FAQS,
    payload: {
      faqs: value
    }
  };
}

export function initFaqs() {
  return {
    type: INIT_FAQS,
    payload: {
      faqs: {}
    }
  }
}

const INIT_STATE = {
  faqs: {}
}

export default function faqsDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_FAQS:
      newState.faqs = action.payload.faqs;
      break;
    case INIT_FAQS:
      newState.faqs = {};
      break;
    default:
      break;
  }
  return newState;
}