const SET_AVAILABLE_SERVICES = 'SET_AVAILABLE_SERVICES'
const INIT_AVAILABLE_SERVICES = 'INIT_AVAILABLE_SERVICES'

export function setAvailableServices(value) {
  return {
    type: SET_AVAILABLE_SERVICES,
    payload: {
      availableServices: value
    }
  };
}

export function initAvailableFlights() {
  return {
    type: INIT_AVAILABLE_SERVICES,
    payload: {
      availableServices: []
    }
  }
}

const INIT_STATE = {
  availableServices: []
}

export default function availableServicesDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_AVAILABLE_SERVICES:
      newState.availableServices = action.payload.availableServices;
      break;
    case INIT_AVAILABLE_SERVICES:
      newState.availableServices = [];
      break;
    default:
      break;
  }
  return newState;
}