import React from "react";

// STYLES
import "./Loading.css";

// ASSETS
import logo from "../../assets/images/logo/notext-logo.svg";
import logoText from "../../assets/images/logo/logo-sos-travel-gray-text.svg"

import { Image, Row, Col } from "antd";

const Loading = () => {
  return (
    <Row className={"loading-page-container centered"}>
      <Col>
        <Image
          preview={false}
          src={logo}
          className={"loading-page-notext-logo"}
        />
        <Row className={"loading-page-notext-logo-shadow"} />
      </Col>
      <Col className={"loading-page-text-logo-container"}>
        <Image
          preview={false}
          src={logoText}
          className={"loading-page-text-logo"}
        />
        <Row className={"loading-page-notext-logo-shine"} />
      </Col>
    </Row>
  )
}

export default Loading