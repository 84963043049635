import React, { useState } from "react";

// STYLES
import './HeaderFlightSearch.css';

// COMPONENTS
import CustomButton from "../../functional_components/customButton/CustomButton";
import FlightSearchInput from "../../functional_components/flightSearchInput/FlightSearchInput";
import HeaderFlightsListItem from "../../functional_components/headerFlightsListItem/HeaderFlightsListItem";

// ASSETS
import SwitchArrows from "../../../assets/images/searchFlight/switch-arrows.svg";
import MenuArrow from "../../../assets/images/searchFlight/menu-arrow.svg";
import DepartureIcon from "../../../assets/images/searchFlight/departure-icon.svg";
import ArrivalIcon from "../../../assets/images/searchFlight/arrival-icon.svg";
import SearchIcon from "../../../assets/images/searchFlight/search-icon.svg";

// REDUX
import { useDispatch, connect } from "react-redux";
import { doSearchFlights } from "../../../redux/actions/actions";
import { setAvailableServices } from "../../../redux/ducks/AvailableServices";

import { DatePicker, Button, Image, Select, Row, Col, Spin, Typography, ConfigProvider } from "antd";
import { ENVIRONMENT } from "../../../utils/properties";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import moment from "moment";
import { useTranslation } from 'react-i18next';

const HeaderFlightSearch = (props) => {

  const [state, setState] = useState({
    flightDate: '',
    searchFor: 'FlightRoute',
    loading: false,
    error: false
  })

  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Text } = Typography;

  const airportsList = props.airportsDuck.airports.response;
  const airlinesList = props.airlinesDuck.airlines.response;

  const formikSearchFlight = useFormik({
    initialValues: {
      from: null,
      to: null,
      flightNumber: '',
      airline: null
    },
    onSubmit: (values) => {
      sendSearch(values);
    }
  });

  const sendSearch = async (values) => {
    setState({
      ...state,
      loading: true
    })
    if (state.searchFor === "FlightRoute") {
      if (!values.from || !values.to) {
        return (
          setState({
            ...state,
            error: true,
            loading: false
          })
        )
      }
      const searchData = {
        date: moment(state.flightDate._d).format('YYYY/MM/DD'),
        from: values.from,
        to: values.to
      }
      await doSearchFlights(dispatch, searchData);
    } else {
      if (!values.flightNumber || !values.airline) {
        return (
          setState({
            ...state,
            error: true,
            loading: false
          })
        )
      }
      const searchData = {
        date: moment(state.flightDate._d).format('YYYY/MM/DD'),
        flightNumber: values.flightNumber,
        airline: values.airline
      }
      await doSearchFlights(dispatch, searchData);
    }
    setState({
      ...state,
      loading: false
    })
  }

  const selectHandleChange = (value) => {
    setState({
      ...state,
      searchFor: value
    })
  }

  const onFlightDateChange = (dateString) => {
    setState({
      ...state,
      flightDate: dateString,
      error: false
    })
  }

  const handleSwipeClick = () => {
    let fromValue = formikSearchFlight.values.from;
    let toValue = formikSearchFlight.values.to;
    formikSearchFlight.values.from = toValue;
    formikSearchFlight.values.to = fromValue;

    setState({
      ...state,
      error: false
    })
  }


  const handleFromInputChange = (value) => {
    formikSearchFlight.values.from = value;
    setState({
      ...state,
      error: false
    })
  }

  const handleToInputChange = (value) => {
    formikSearchFlight.values.to = value;
    setState({
      ...state,
      error: false
    })
  }

  const handleAirlineInputChange = (value) => {
    formikSearchFlight.values.airline = value;
    setState({
      ...state,
      error: false
    })
  }

  const searchIcon = () => {
    return (
      <>
        <Image
          src={SearchIcon}
          alt={"search icon"}
          preview={false}
        />
      </>
    )
  }

  const goToFlightDetail = (flight) => () => {
    let availableServices = ["LLC"]
    if (flight.departureModel.hasLounges) {
      availableServices.push("VIPLOUNGE")
    }
    dispatch(setAvailableServices(availableServices))
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}pre-checkout`);
  }

  const disabledDate = (current) => {
    return current < moment().startOf('day');
  }

  return (
    <div className={'home-header-flight-search-container'}>
      <Select
        defaultValue={"FlightRoute"}
        onChange={selectHandleChange}
        className={"home-header-flight-search-select"}
        suffixIcon={<Image src={MenuArrow} alt={"open menu"} preview={false} />}
      >
        <Option value="FlightRoute">{t("home.searchFlights.searchForRoute")}</Option>
        <Option value="FlightNumber">{t("home.searchFlights.searchForFlightNumber")}</Option>
      </Select>
      <Row className={(state.loading || (props.flightsDuck.flights.response && !state.loading)) ? 'home-header-flight-search-inputs-container home-header-flight-search-inputs-container-results' : 'home-header-flight-search-inputs-container'}>
        {
          state.searchFor === "FlightRoute" &&
          <>
            <FlightSearchInput
              hideText
              id={'from'}
              onChange={handleFromInputChange}
              value={formikSearchFlight.values.from}
              placeholder={t("home.searchFlights.from")}
              icon={DepartureIcon}
              iconAlt={t("home.searchFlights.from")}
              airportsList={airportsList}
            />

            <FlightSearchInput
              hideText
              id={'to'}
              onChange={handleToInputChange}
              value={formikSearchFlight.values.to}
              placeholder={t("home.searchFlights.to")}
              icon={ArrivalIcon}
              iconAlt={t("home.searchFlights.to")}
              airportsList={airportsList}
            />

            <Button
              onClick={handleSwipeClick}
              className={'home-header-flight-search-input-swipe-button'}>
              <Image
                src={SwitchArrows}
                alt={"switch"}
                className={"home-flight-search-switch-arrows"}
                preview={false}
              />
            </Button>
          </>
        }
        {
          state.searchFor === "FlightNumber" &&
          <>
            <FlightSearchInput
              id={'airline'}
              onChange={handleAirlineInputChange}
              value={formikSearchFlight.values.airline}
              placeholder={t("home.searchFlights.airline")}
              airlinesList={airlinesList}
            />

            <FlightSearchInput
              id={'flightNumber'}
              onChange={formikSearchFlight.handleChange}
              value={formikSearchFlight.values.flightNumber}
              placeholder={"1234"}
            />
          </>
        }
        <div className={"home-header-flight-search-input-vertical-line"} />
        <div className={'home-flight-date-input-container'}>
          <ConfigProvider locale={props.locale}>
            <DatePicker
              onChange={onFlightDateChange}
              className={'home-flight-search-input'}
              format={"DD/MM/YYYY"}
              disabledDate={disabledDate}
            />
          </ConfigProvider>

        </div>

        <CustomButton
          content={searchIcon()}
          clickCallback={formikSearchFlight.submitForm}
          type={'searchFlight'}
        />
      </Row>
      {
        state.error &&
        <Text className={"home-flight-error-message"}>*{t("home.searchFlights.errorMessage")}</Text>
      }
      {
        state.loading &&
        <Col className={'home-header-flight-search-input-result-list'}>
          <Col className={"home-header-flight-search-spin-container"}>
            <Spin size={"medium"} />
          </Col>
        </Col>
      }
      {
        props.flightsDuck.flights.response && !state.loading &&
        <Col className={'home-header-flight-search-input-result-list'}>
          {
            props.flightsDuck.flights.response.length > 0 &&
            <>
              <div className={"home-header-flight-result-available-services-text"}>{t("checkout.availableServices")}</div>
              {
                props.flightsDuck.flights.response.map((flight, index) => {
                  return (
                    <div key={index} onClick={goToFlightDetail(flight)}>
                      <HeaderFlightsListItem
                        carrier={flight.carrier}
                        airlineLogo={flight.carrierModel.airlineLogoUrl}
                        departure={flight.departure}
                        departureCity={flight.departureModel.city}
                        arrival={flight.arrival}
                        arrivalCity={flight.arrivalModel.city}
                        flightDuration={flight.flightProgressModel.flightDuration}
                        departureHours={moment(flight.departingDate.dateLocal).format('HH:mm')}
                        departureDate={moment(flight.departingDate.dateLocal).format('D MMM')}
                        arrivalHours={moment(flight.arrivingDate.dateLocal).format('HH:mm')}
                        arrivalDate={moment(flight.arrivingDate.dateLocal).format('D MMM')}
                        flightCode={flight.carrier + flight.numberFlight}
                        stopovers={flight.connectionFlight}
                        LLC
                        VIPLOUNGE={flight.departureModel.hasLounges}
                      />
                    </div>
                  )
                })
              }
            </>
          }
          {
            props.flightsDuck.flights.response.length === 0 &&
            <Col className={"centered"}>
              <Text>{t("home.searchFlights.noResultsMessage")}</Text>
            </Col>
          }
        </Col>
      }

    </div>
  )
}

const mapStateToProps = state => ({
  airportsDuck: state.airportsDuck,
  flightsDuck: state.flightsDuck,
  airlinesDuck: state.airlinesDuck
})

export default connect(mapStateToProps)(HeaderFlightSearch)