import React from 'react';
import { notification } from 'antd';
import { getTranslation } from '../i18n/i18n-config';
import { replace, toNumber } from 'lodash';
import NumberFormat from 'react-number-format';

import { ENVIRONMENT } from './properties';

import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

// LOCALE
import IT from 'antd/lib/locale/it_IT';
import PT from 'antd/lib/locale/pt_PT';
import RU from 'antd/lib/locale/ru_RU';
import ES from 'antd/lib/locale/es_ES';
import FR from 'antd/lib/locale/fr_FR';


export const getUserInfoLocal = (key = '') => {
  let tempUserInfoLocal = localStorage.getItem('userInfo');

  let userInfoLocal = null;
  if(tempUserInfoLocal) {
     userInfoLocal = CryptoAES.decrypt(tempUserInfoLocal, "MEGAKEY").toString(CryptoENC);
  }

  const userInfo = JSON.parse(userInfoLocal);
  let dataReturn = null;
  if (key !== '' && userInfo !== null && userInfo[key]) {
    dataReturn = userInfo[key];
  } else if (key === '') {
    dataReturn = userInfo;
  }
  return dataReturn;
}

export const showError = (error) => {
  notification.open({
    duration: 5,
    className: 'notification-error',
    message: getTranslation('general', 'ErrorNotificationTitle'),
    description: getTranslation('errorsCode', error)
  });
}

export const showSuccess = (message = "SuccessNotificationContent") => {
  notification.open({
    duration: 5,
    className: 'notification-success',
    message: getTranslation('general', 'SuccessNotificationTitle'),
    description: getTranslation('general', message)
  });
}

export const buildQueryString = (actQS, data) => {
  let newQueryString = '';
  Object.keys(data).forEach(k => {
    actQS[k] = data[k];
  });
  Object.keys(actQS).forEach((k, i) => {
    newQueryString = newQueryString + (i !== 0 ? `&${k}=${!actQS[k] ? '' : actQS[k]}` : `${k}=${actQS[k]}`);
  });
  return { string: newQueryString, obj: actQS };
}


export const formatNumber = (props) => {
  let numberSuffix;
  switch (props.suffix) {
    case 'PERCENT':
      numberSuffix = ' %';
      break;
    case 'HOUR':
      numberSuffix = ' h';
      break;
    case 'EUR':
      numberSuffix = ' €';
      break;
    default:
      numberSuffix = '';
      break;
  }
  return <NumberFormat
    id={props.id}
    name={props.name}
    value={props.value}
    key={props.key}
    displayType={props.displayType ? props.displayType : 'text'}
    thousandSeparator={props.lang === 'GB' ? ',' : '.'}
    decimalSeparator={props.lang === 'GB' ? '.' : ','}
    decimalScale={2}
    fixedDecimalScale
    suffix={numberSuffix}
    customInput={props.customInput}
    onChange={props.onChange}
    onBlur={props.onBlur}
    isAllowed={props.isAllowed}
    disabled={props.disabled}
    allowEmptyFormatting={props.displayType === 'input'}
  />
}

export const unformatNumber = (value, lang) => {
  const regexThousand = lang === 'GB' ? /([, €])/g : /([. €])/g;
  const regexDecimal = lang === 'GB' ? /([.])/g : /([,])/g;
  let number = replace(value, regexThousand, '');
  number = replace(number, regexDecimal, '.');
  return toNumber(number);
}

export const providersURL = (pathname, provider) => {
  return `${ENVIRONMENT.API_REST_BE}userauth/oauth2/authorize/${provider}?redirect_uri=${ENVIRONMENT.PROVIDER_BASE_URL}provider?pathname=${pathname}`
}

export const returnValue = (basePath, val) => {
  let value = '-';
  if (basePath) {
    if (basePath[val]) {
      value = basePath[val];
    }
  }
  return value;
}

export const getValueFromLang = (value, lang, valueistext) => {
  let newValue;
  value.map((val) => {
    if (val.lang === lang.toLowerCase()) {
      if (valueistext) {
        newValue = val.text;
      } else {
        newValue = val.value;
      }
    }
  })
  return newValue;
}

export const formatValue = (value) => {
  return <NumberFormat
    value={value}
    type={"text"}
    displayType={"text"}
    decimalSeparator={","}
    fixedDecimalScale={true}
    decimalScale={2}
    isNumericString={true}
  />
}

export const setLocale = (lang) => {
  let newLocale;
  switch (lang) {
    case ("IT"):
      newLocale = IT;
      break;
    case ("ES"):
      newLocale = ES;
      break;
    case ("RU"):
      newLocale = RU;
      break;
    case ("PT"):
      newLocale = PT;
      break;
    case ("FR"):
      newLocale = FR;
      break;
    default:
      newLocale = null;
  }
  return newLocale;
}