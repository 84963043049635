import React, { useState } from "react";

// STYLES
import "./CheckoutUserData.css";

// COMPONENTS
import PrefixPhoneMenu from "../../hooks_components/prefixPhoneMenu/PrefixPhoneMenu";

import { useTranslation } from "react-i18next";
import { Col, Row, Input } from "antd";
import ReactFlagsSelect from "react-flags-select";

const CheckoutUserData = (props) => {
  const [state, setState] = useState({
    areacode: "39",
    country: props.userData.data.userData.country
      ? props.userData.data.userData.country.toUpperCase()
      : null,
  });

  const { t } = useTranslation();

  const prefixHandleChange = (value, prefixPhoneCountry) => {
    setState({
      ...state,
      areacode: value,
    });

    props.prefixHandleChange(value, prefixPhoneCountry);
    props.removeErrorMessage();
  };

  const handleCountryChange = (value) => {
    setState({
      ...state,
      country: value,
    });

    props.countryHandleChange(value);
    props.removeErrorMessage();
  };

  const checkNumber = (e) => {
    if ((e.which != 8 && e.which != 0 && e.which < 48) || e.which > 57) {
      e.preventDefault();
    }
  };

  return (
    <Col className={"checkout-user-data-container"}>
      <Row className={"checkout-user-data-responsive-input-container"}>
        <Col className={"checkout-user-data-input-container"}>
          <label>{t("general.name")}</label>
          <Input
            id={"name"}
            placeholder={t("general.name")}
            value={
              props.userData.data.name
                ? props.userData.data.name
                : props.nameValue
            }
            onChange={props.handleChange}
            autoComplete={"off"}
            disabled={props.userData.data.name}
            onFocus={props.removeErrorMessage}
          />
        </Col>
        <Col className={"checkout-user-data-input-container"}>
          <label>{t("general.surname")}</label>
          <Input
            id={"surname"}
            placeholder={t("general.surname")}
            value={
              props.userData.data.surname
                ? props.userData.data.surname
                : props.surnameValue
            }
            onChange={props.handleChange}
            autoComplete={"off"}
            disabled={props.userData.data.surname}
            onFocus={props.removeErrorMessage}
          />
        </Col>
      </Row>
      <Col className={"checkout-user-data-input-container"}>
        <label>{t("general.email")}</label>
        <Input
          id={"email"}
          placeholder={t("general.email")}
          value={
            props.userData.data.email
              ? props.userData.data.email
              : props.emailValue
          }
          onChange={props.handleChange}
          autoComplete={"off"}
          disabled={props.userData.data.email}
          onFocus={props.removeErrorMessage}
        />
      </Col>
      <Col className={"checkout-user-data-input-container"}>
        <label>
          {t("general.phone")} ({t("general.optional")})
        </label>
        <Row className={"checkout-user-data-phone-container"}>
          <PrefixPhoneMenu
            handleChangeCallback={prefixHandleChange}
            disabled={props.userData.data.prefixPhone}
          />
          <Input
            id={"phone"}
            name={"phone"}
            type={"number"}
            placeholder={"123456"}
            onChange={props.handleChange}
            onKeyPress={checkNumber}
            value={
              props.userData.data.phone
                ? props.userData.data.phone
                : props.phoneValue
            }
            autoComplete={"off"}
            disabled={props.userData.data.phone}
            onFocus={props.removeErrorMessage}
          />
        </Row>
      </Col>
      <Col
        className={
          "checkout-user-data-input-container checkout-user-country-input-container"
        }
      >
        <label>{t("general.country")}</label>
        <ReactFlagsSelect
          selected={state.country}
          onSelect={handleCountryChange}
          searchable
          selectedSize={14}
          optionsSize={14}
          fullWidth={false}
          disabled={props.userData.data.userData.country}
          placeholder={t("general.selectCountry")}
        />
      </Col>
    </Col>
  );
};

export default CheckoutUserData;
