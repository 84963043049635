import React from "react";

// STYLES
import './HomeNoStress.css';

// ASSETS
import AppleStore from "../../../assets/images/home/apple-store-button.svg";
import PlayStore from "../../../assets/images/home/google-play-button.svg";

import { Typography, Row, Image } from "antd";
import CustomButton from "../customButton/CustomButton";
import { useTranslation } from 'react-i18next';

const HomeNoStress = (props) => {

  const { Title, Text } = Typography;
  const { t } = useTranslation();

  const storeButton = (value) => () => {
    if(value === "download") {
      return window.open("http://get.sostravel.com/", "_blank");
    }
    if(value === "apple") {
      return window.open("https://apps.apple.com/it/app/sostravel/id1068419699", "_blank");
    }
    if(value === "play") {
      return window.open("https://play.google.com/store/apps/details?id=com.safebag.safebag24&hl=it", "_blank");
    }

    window.open(value, "_blank");
  }

  return (
    <div className={'home-no-stress-container'}>
      <div
        className={'home-no-stress-inner-container'}
        style={{backgroundImage: `url(${props.src})`}}
      >
      <div className={'home-no-stress-image-mask'} />
      <Title className={'home-no-stress-title'}>{props.title}</Title>
      <div className={'home-no-stress-subtitle-container'}>
        <Text className={'home-no-stress-subtitle'}>{props.subtitle}</Text>
      </div>
      <div className={'home-no-stress-button-container'}>
        <CustomButton
          type={'primary-xl'}
          content={props.label}
          clickCallback={storeButton(props.link)}
        />
      </div>
      <Row className={"home-no-stress-buttons-container"}>
        <Image
          src={AppleStore}
          alt={"Apple Store"}
          preview={false}
          onClick={storeButton('apple')}
        />
        <Image
          src={PlayStore}
          alt={"Play Store"}
          preview={false}
          onClick={storeButton('play')}
        />
      </Row>
    </div>
    </div >
  )
}

export default HomeNoStress