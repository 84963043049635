import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "antd/dist/antd.css";
import "./style.css";
import App from "./App";
import "./i18n/i18n-config";
import { Provider } from "react-redux";
import store from "./redux/createStore";
import TagManager from "react-gtm-module";

//GOOGLE TAG MANAGER
const tagManagerArgs = {
  gtmId: "GTM-PF3THQ3",
};
TagManager.initialize(tagManagerArgs);

function RedirectToLanguage() {
  const { pathname, search } = useLocation();
  const { i18n } = useTranslation();
  const langs = ["/it/", "/en/", "/ru/", "/pt/", "/es/", "/fr/"];
  if (!langs.some((l) => pathname.includes(l))) {
    window.location.href =
      "/" + i18n.resolvedLanguage + pathname + (search ? search : "");
  } else {
    const chosenLang = langs
      .find((l) => pathname.includes(l))
      .replace(/\//g, "");
    if (i18n.resolvedLanguage !== chosenLang) {
      i18n.changeLanguage(
        langs.find((l) => pathname.includes(l)).replace(/\//g, "")
      );
      window.location.reload();
    }
  }
  return null;
}

function Root() {
  const { i18n } = useTranslation();
  return (
    <Provider store={store}>
      <BrowserRouter basename={i18n.resolvedLanguage}>
        <App />
      </BrowserRouter>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<RedirectToLanguage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

ReactDOM.render(
  // <React.StrictMode>
  <Root />,
  // </React.StrictMode>
  document.getElementById("root")
);
