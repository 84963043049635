import React, { useEffect, useState } from "react";

// STYLES
import './CheckoutServiceCard.css';

// COMPONENTS
import HomeServiceContent from "../homeServiceContent/HomeServiceContent";

// ASSETS
import Arrow from "../../../assets/images/homeServicesCarousel/left-arrow.svg";
import CheckIconGreen from "../../../assets/images/checkout/check-icon-green.svg";

// REDUX
import { setServices } from "../../../redux/ducks/Services";
import { connect, useDispatch } from "react-redux";

import { Image, Col, Typography, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";

const CheckoutServiceCard = (props) => {

  const [state, setState] = useState({
    servicesCount: 0
  })

  const { Text } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    initialServiceCount();
  }, [props.servicesDuck])

  const initialServiceCount = () => {
    let newServiceCount = 0;

    if (props.servicesDuck.services.length > 0) {
      props.servicesDuck.services.map((service) => {
        if (service.name === props.cardCode) {
          newServiceCount = service.value;
          setState({
            ...state,
            servicesCount: newServiceCount
          })
        }
      })
    }
  }

  const addItem = () => {
    let newServiceCount = state.servicesCount + 1;
    let servicesObj = props.servicesDuck.services;

    let objIndex = null;

    servicesObj.map((item, index) => {
      if (item.name === props.cardCode) {
        objIndex = index;
      }
    })

    servicesObj[objIndex].value = newServiceCount;

    setState({
      ...state,
      servicesCount: newServiceCount
    })

    dispatch(setServices(servicesObj));
  }

  const removeItem = () => {
    let newServiceCount = state.servicesCount - 1;
    let servicesObj = props.servicesDuck.services;

    let objIndex = null;

    servicesObj.map((item, index) => {
      if (item.name === props.cardCode) {
        objIndex = index;
      }
    })

    if (newServiceCount === 0) {
      servicesObj.splice(objIndex, 1);
    } else {
      servicesObj[objIndex].value = newServiceCount;
    }

    setState({
      ...state,
      servicesCount: newServiceCount
    })

    dispatch(setServices(servicesObj));

    if (location.pathname === "/services-checkout" && servicesObj.length === 0) {
      navigate(`${ENVIRONMENT.ROUTING.BASE_URL}`);
    }
    if (location.pathname === "/B2B/checkout" && servicesObj.length === 0) {
      navigate(`${ENVIRONMENT.ROUTING.BASE_URL}B2B/home`);
    }
  }

  const getPrice = () => {
    let price;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === props.cardCode) {
        price = product.prices;
      }
    });
    return price;
  }

  const confirmService = () => {
    let servicesObj = props.servicesDuck.services;
    servicesObj.push({
      name: props.cardCode,
      value: 1,
      prices: getPrice()
    })
    setState({
      ...state,
      servicesCount: 1
    })

    dispatch(setServices(servicesObj));
  }

  const removeService = () => {
    let servicesObj = props.servicesDuck.services;
    servicesObj.map((service, i) => {
      if (service.name === props.cardCode) {
        servicesObj.splice(i, 1)
      }
    })
    dispatch(setServices(servicesObj));

    if (location.pathname === "/services-checkout" && servicesObj.length === 0) {
      navigate(`${ENVIRONMENT.ROUTING.BASE_URL}`);
    }
    if (location.pathname === "/B2B/checkout" && servicesObj.length === 0) {
      navigate(`${ENVIRONMENT.ROUTING.BASE_URL}B2B/home`);
    }
  }

  return (
    <Col
      className={props.siderMenu ? "checkout-service-card-container maxWidth-100" : "checkout-service-card-container"}
      style={props.siderMenu ? { boxShadow: "none", borderBottom: "1px solid var(--secondary-gray)", borderRadius: 0, paddingLeft: "60px", paddingTop: "5px" } : {}}
    >
      {
        !props.noRemove &&
        <Text
          className={"checkout-service-card-remove-text"}
          onClick={removeService}
        >
          {t("checkout.remove")}
        </Text>
      }

      <div
        className={'checkout-service-card-badge'}
        style={props.siderMenu ? { top: "10px" } : {}}
      >
        <Image
          src={props.badge}
          alt={'icon'}
          className={'checkout-service-card-badge-image'}
          preview={false}
        />
      </div>

      <HomeServiceContent
        cardTitle={props.cardTitle}
        cardSubtitle={props.cardSubtitle}
        cardDescription={props.cardDescription}
        currency={props.currency}
        priceForItem={props.priceForItem}
        subtitleColor={"var(--main-gray)"}
        descriptionColor={"var(--secondary-gray)"}
        noDescription={props.noDescription}
        siderMenu={props.siderMenu}
        target={props.target}
      />

      <Row className={props.noDescription ? "checkout-service-card-footer-container checkout-service-card-footer-no-description-container" : "checkout-service-card-footer-container"}>
        {
          state.servicesCount > 0 &&
          <Col className={"checkout-service-card-footer-add-buttons-container"}>
            <Image
              src={Arrow}
              alt={'minus'}
              preview={false}
              onClick={removeItem}
            />
            <div className={"checkout-service-card-footer-numbers-container"}>{state.servicesCount}</div>
            <Image
              src={Arrow}
              alt={'plus'}
              preview={false}
              style={{ transform: 'rotate(180deg)' }}
              onClick={addItem}
            />
          </Col>
        }


        <Col className={"checkout-service-card-footer-confirm-button-container"}>
          {
            state.servicesCount > 0 && !props.siderMenu &&
            <Button
              className={'checkout-service-card-footer-confirm-button green-border'}
            >
              <Image
                src={CheckIconGreen}
                alt={'checked'}
                preview={false}
              />
            </Button>
          }
          {
            state.servicesCount === 0 &&
            <Button
              className={'checkout-service-card-footer-confirm-button'}
              onClick={confirmService}
            >
              <span>+</span>
            </Button>
          }
        </Col>
      </Row>
    </Col>
  )
}

CheckoutServiceCard.defaultProps = {
  currency: '€',
  priceForItem: '-'
}

const mapStateToProps = state => ({
  servicesDuck: state.servicesDuck,
  productsListDuck: state.productsListDuck
})

export default connect(mapStateToProps)(CheckoutServiceCard)