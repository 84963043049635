import React, { useState } from "react";

// STYLES
import './ForgotPassword.css';

// COMPONENTS
import PrefixPhoneMenu from "../../components/hooks_components/prefixPhoneMenu/PrefixPhoneMenu";
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import SEO from "../../components/functional_components/SEO/SEO";

import { emailValidation, phoneValidation } from "../../utils/properties";
import { Col, Typography, Row, Menu, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../utils/properties";

// REDUX
import { doForgotPsw } from "../../redux/actions/actions";


const ForgotPassword = () => {

  const [state, setState] = useState({
    isLoading: false,
    accessType: 'email',
    areacode: '+39',
    validEmail: true,
    validPhone: true,
    emailSent: false,
    inputData: null
  });

  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formikResetPsw = useFormik({
    initialValues: {
      email: '',
      phone: '',
    },
    onSubmit: (values) => {
      sendData(values);
    }
  });

  const sendData = async (values) => {
    setState({
      ...state,
      isLoading: true
    })
    let resetPasswordData = {};

    if (state.accessType === "email") {
      if (!emailValidation(values.email)) {
        return (
          setState({
            ...state,
            validEmail: false
          })
        )
      }
      resetPasswordData.username = values.email;
      resetPasswordData.source = "SOSTRAVEL";
    } else {
      if (!phoneValidation(`${values.phone}`)) {
        return (
          setState({
            ...state,
            validPhone: false
          })
        )
      }
      resetPasswordData.prefix = state.areacode;
      resetPasswordData.phone = values.phone;
      resetPasswordData.source = "SOSTRAVEL";
    }

    let response = await doForgotPsw(resetPasswordData);
    
    if(response === undefined) {
      return setState({
        ...state,
        isLoading: false
      })
    }

    setState({
      ...state,
      inputData: resetPasswordData,
      emailSent: true,
      isLoading: false
    })
  }

  const handleClick = e => {
    setState({
      ...state,
      accessType: e.key
    });
  };

  const prefixHandleChange = (value) => {
    setNewPrefix(value);
  }

  const setNewPrefix = (value) => {
    setState({
      ...state,
      areacode: value
    });
  }

  const handleEmailInputFocus = () => {
    setState({
      ...state,
      validEmail: true
    })
  }

  const handlePhoneInputFocus = () => {
    setState({
      ...state,
      validPhone: true
    })
  }

  const backToLogin = () => {
    navigate(`${ENVIRONMENT.ROUTING.SIGNIN_URL}`);
  }

  return (
    <Col className={"forgot-psw-container"}>
      <SEO
        page={"ForgotPsw"}
        url={"forgot-password"}
      />

      <Title className={"forgot-psw-title"}>{t("forgotPassword.title")}</Title>
      <Text className={"forgot-psw-subtitle"}>{t("forgotPassword.subtitle")}</Text>

      {
        !state.emailSent &&
        <>
          <Menu
            onClick={handleClick}
            selectedKeys={state.accessType}
            mode="horizontal"
            className={"login-access-type-menu-container"}
          >
            <Menu.Item key="email" style={state.accessType === 'email' ? { color: 'var(--main-black)' } : { color: 'var(--secondary-gray)' }}>
              {t('general.email')}
            </Menu.Item>
            <Menu.Item key="phone" style={state.accessType === 'email' ? { color: 'var(--secondary-gray)' } : { color: 'var(--main-black)' }}>
              {t('general.phone')}
            </Menu.Item>
          </Menu>
          {
            state.accessType === 'email' &&
            <Row>
              <label className='login-label'>{t('general.emailLabel')}</label>
              <Input
                id="email"
                name="email"
                placeholder={t('general.emailPlaceholder')}
                onChange={formikResetPsw.handleChange}
                onBlur={formikResetPsw.handleBlur}
                value={formikResetPsw.values.email}
                className={state.validEmail ? 'login-input-box' : 'login-input-box invalid-field'}
                onFocus={handleEmailInputFocus}
                autoComplete={"off"}
              />
              {
                !state.validEmail &&
                <Text className={'invalid-field-message'}>{t('general.invalidEmail')}</Text>
              }
            </Row>
          }
          {
            state.accessType === 'phone' &&
            <Row>
              <label className={'login-label'}>{t('general.phone')}</label>
              <Row className={'login-input-phone-container'}>
                <PrefixPhoneMenu
                  handleChangeCallback={prefixHandleChange}
                />
                <Input
                  id="phone"
                  name="phone"
                  placeholder={'123456'}
                  onChange={formikResetPsw.handleChange}
                  value={formikResetPsw.values.phone}
                  className={state.validPhone ? 'login-input-box login-input-phone' : 'login-input-box login-input-phone invalid-field'}
                  type={'number'}
                  onFocus={handlePhoneInputFocus}
                  autoComplete={"off"}
                />
                {
                  !state.validPhone &&
                  <Text className={'invalid-field-message'}>{t('general.invalidPhone')}</Text>
                }
              </Row>
            </Row>
          }
        </>
      }
      {
        state.emailSent && state.accessType === "email" &&
        <Col className={"forgot-password-email-sent-text-container"}>
          <Text>{t("forgotPassword.emailSentText")} <b>{state.inputData.username}</b>, {t("forgotPassword.checkEmail")}.</Text>
        </Col>
      }
      {
        state.emailSent && state.accessType === "phone" &&
        <Col className={"forgot-password-email-sent-text-container"}>
          <Text>{t("forgotPassword.messageSentText")} <b>{state.inputData.prefix}{state.inputData.phone}</b>, {t("forgotPassword.checkPhone")}.</Text>
        </Col>
      }
      <Row className={"forgot-password-submit-button-container"}>
        {
          !state.emailSent &&
          <CustomButton
            content={t("general.continue")}
            clickCallback={formikResetPsw.submitForm}
            isLoading={state.isLoading}
          />
        }
        {
          state.emailSent &&
          <CustomButton
            content={t("forgotPassword.backToLogin")}
            clickCallback={backToLogin}
          />
        }
      </Row>
    </Col>
  )
}

export default ForgotPassword