import React from "react";

// STYLES
import './VipInputBox.css';

// ASSETS
import Profile from '../../../assets/images/checkout/profile-icon.svg';

import { Row, Col, Image, Input, DatePicker, ConfigProvider } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { setLocale } from "../../../utils/utilities";

const VipInputBox = (props) => {

  const { t } = useTranslation();

  const getValue = (e) => {
    props.handleChange({
      target: {
        value: moment(e._d).format("YYYY-MM-DD"),
        name: "startDate"
      }
    });
  }

  const disabledDate = (current) => {
    return current < moment().startOf('day');
  }

  return (
    <div>
      <Row className={"flex-sb mt-10"}>
        <Col className={"vip-input-profile-icon-container centered"}>
          <Image
            src={Profile}
            alt={'Profile'}
            preview={false}
          />
          <div className={"vip-input-profile-id centered"}>
            {props.id + 1}
          </div>
        </Col>

        <Col className={"vip-input-profile-fields-container"}>
          <Input
            name={"name"}
            placeholder={t("general.name")}
            onChange={props.handleChange}
            autoComplete={"off"}
          />
          <Input
            name={"surname"}
            placeholder={t("general.surname")}
            onChange={props.handleChange}
            autoComplete={"off"}
          />
          <ConfigProvider locale={setLocale(props.languageDuck.currentLanguage)}>
            <DatePicker
              onChange={getValue}
              max={"2999-12-31"}
              className={"vip-input-profile-fields-date-picker"}
              placeholder={t("home.searchFlights.departureDate")}
              disabledDate={disabledDate}
            />
          </ConfigProvider>

        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = state => ({
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(VipInputBox)