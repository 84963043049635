import React, { useState } from "react";

// STYLES
import '../SignIn/SignIn.css';

// COMPONENTS
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import SEO from "../../components/functional_components/SEO/SEO";

// REDUX
import { doLogin } from "../../redux/actions/actions";
import { connect, useDispatch } from "react-redux";

import { ENVIRONMENT, emailValidation, passwordValidation } from '../../utils/properties';
import { Col, Row, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { initProductsList } from "../../redux/ducks/ProductsList";

const B2B_SignIn = (props) => {
  const [state, setState] = useState({
    validEmail: true,
    validPassword: true
  })

  const { t } = useTranslation();
  const { Text, Title } = Typography;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formikLogin = useFormik({
    initialValues: {
      username: '',
      phone: '',
      password: ''
    },
    onSubmit: (values) => {
      sendLogin(values);
    }
  });

  const sendLogin = async (values) => {
    try {
      const dataLogin = {
        email: values.username,
        password: values.password,
        source: "SOSTRAVEL"
      }

      if (!emailValidation(dataLogin.email)) {
        return (
          setState({
            ...state,
            validEmail: false
          })
        )
      }

      if (!passwordValidation(dataLogin.password)) {
        return (
          setState({
            ...state,
            validPassword: false
          })
        )
      }

      let loginData = await doLogin(dispatch, dataLogin, "B2B", t("errorsCode.ER002"));
      dispatch(initProductsList());
      if (!loginData.error) {
        navigate(`${ENVIRONMENT.ROUTING.BASE_URL}B2B/home`);
      }
    } catch (err) {
      console.log('err', err)
    }
  };

  const handleEmailInputFocus = () => {
    setState({
      ...state,
      validEmail: true
    })
  }

  const handlePasswordInputFocus = () => {
    setState({
      ...state,
      validPassword: true
    })
  }

  const handleForgotPsw = () => {
    navigate(`${ENVIRONMENT.ROUTING.SIGNUP_URL}/forgot-password`);
  }

  return (
    <Col className={'login-screen-container b2b-login-screen-container'}>
      <SEO
        page={"B2B_Signin"}
        url={"B2B/signin"}
      />
      
      <Row className={'login-title-container'}>
        <Title className={'login-title'}>{t('signin.title')}</Title>
      </Row>

      <Row className={'login-subtitle-container'}>
        <p className={'login-subtitle'}>{t('signin.subtitle')}</p>
      </Row>

      <Row>
        <label className='login-label'>{t('general.emailLabel')}</label>
        <Input
          id="username"
          name="username"
          placeholder={t('general.emailPlaceholder')}
          onChange={formikLogin.handleChange}
          onBlur={formikLogin.handleBlur}
          value={formikLogin.values.username}
          className={state.validEmail ? 'login-input-box' : 'login-input-box invalid-field'}
          onFocus={handleEmailInputFocus}
          autoComplete={"off"}
        />
        {
          !state.validEmail &&
          <Text className={'invalid-field-message'}>{t('general.invalidEmail')}</Text>
        }
      </Row>

      <Row>
        <Row className={'login-label-password-container'}>
          <label className={'login-label'}>{t('general.password')}</label>
          <p className={'login-forgot-pasword'} onClick={handleForgotPsw}>{t('signin.forgotPassword')}</p>
        </Row>
        <Input.Password
          id="password"
          name="password"
          placeholder={t('general.passwordPlaceholder')}
          onChange={formikLogin.handleChange}
          onBlur={formikLogin.handleBlur}
          value={formikLogin.values.password}
          onFocus={handlePasswordInputFocus}
          className={state.validPassword ? 'login-input-box' : 'login-input-box invalid-field'}
        />
        {
          !state.validPassword &&
          <Text className={'invalid-field-message'}>{t('general.invalidPassword')}</Text>
        }
      </Row>

      <Row className={'login-submit-button-container'}>
        <CustomButton
          type="primary"
          isLoading={state.isLoading}
          clickCallback={formikLogin.submitForm}
          content={t('general.continue')}
        />
      </Row>
    </Col>
  )
}

const mapStateToProps = state => ({
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(B2B_SignIn)