import React from "react";

// ASSETS
import CheckIconGreen from "../../../assets/images/checkout/check-icon-green.svg";
import DeleteIcon from "../../../assets/images/profile/delete-icon.svg";

import { Col, Row, Typography, Image, Button } from "antd";
import { useTranslation } from "react-i18next";

const PaymentMethodCard = (props) => {

  const { Text } = Typography;
  const { t } = useTranslation();

  const handleClick = () => {
    props.handleClick();
  }

  const handleSelect = () => {
    props.handleSelect();
  }

  return (
    <Row
      className={"b2b-payment-method-container"}
      onClick={props.handleSelect ? handleSelect : null}
    >
      <Col className={"b2b-payment-method-card-logo-container centered"}>
        <Image
          src={props.src}
          preview={false}
        />
      </Col>
      <Col className={"b2b-payment-method-card-data-container"}>
        <Text>{props.cardType} {t("checkout.ending")} {props.last4}</Text>
        <Text>{t("checkout.expiry")} {props.expiring}</Text>
      </Col>
      <Col className={"b2b-payment-method-card-button-container centered"}>
        <Col className={"checkout-service-card-footer-confirm-button-container"}>
          {
            !props.delete &&
            <Button
              className={props.selected === props.i ? 'checkout-service-card-footer-confirm-button green-border' : 'checkout-service-card-footer-confirm-button'}
            >
              {
                props.selected === props.i &&
                <Image
                  src={CheckIconGreen}
                  alt={'checked'}
                  preview={false}
                />
              }
              {
                props.selected !== props.i &&
                <span>+</span>
              }
            </Button>
          }
          {
            props.delete &&
            <Button
              className={'checkout-service-card-footer-confirm-button'}
              onClick={handleClick}
            >
              <Image
                src={DeleteIcon}
                alt={'delete'}
                preview={false}
              />
            </Button>
          }

        </Col>
      </Col>
    </Row>
  )
}

export default PaymentMethodCard