import React, { useEffect, useState } from "react";

// STYLES
import "./ProductDetailLayout.css";

// COMPONENTS
import HeaderHome from "../../components/functional_components/headerHome/HeaderHome";
import FooterHome from "../../components/functional_components/footerHome/FooterHome";
import Newsletter from "../../components/hooks_components/newsletter/Newsletter";
import MobileNavbar from "../../components/hooks_components/mobileNavbar/MobileNavbar";
import HomeServiceCarousel from "../../components/functional_components/homeServiceCarousel/HomeServiceCarousel";
import HomeServiceCard from "../../components/functional_components/homeServiceCard/HomeServiceCard";
import SearchVIPLounge from "../../components/functional_components/searchVIPLounge/SearchVIPLounge";
import Loading from "../../screens/Loading/Loading";

//REDUX
import { connect, useDispatch } from "react-redux";
import {
  doSearchAirports,
  getProductsList,
  getNewsletterData,
  getPagesData,
} from "../../redux/actions/actions";

import { Outlet } from "react-router";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { getValueFromLang } from "../../utils/utilities";

const ProductDetailLayout = (props) => {
  const [state, setState] = useState({
    loaded: false,
  });

  const { Header, Content, Footer } = Layout;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (Object.keys(props.airportsDuck.airports).length === 0) {
      await doSearchAirports(dispatch, {
        lang: props.languageDuck.currentLanguage.toLowerCase(),
      });
    }

    if (Object.keys(props.productsListDuck.productsList).length === 0) {
      await getProductsList(dispatch);
    }

    if (Object.keys(props.newsletterDataDuck.newsletterData).length === 0) {
      await getNewsletterData(dispatch, "newsletter");
    }

    if (Object.keys(props.productsDataDuck.productsData).length === 0) {
      await getPagesData(dispatch);
    }

    setState({
      ...state,
      loaded: true,
    });
  };

  const getValue = (item) => {
    let title;
    let src;
    let description;
    let content;
    let link;

    let lang = props.languageDuck.currentLanguage;
    let productdata =
      props.productsDataDuck.productsData[`${props.product}Data`].data
        .components;

    switch (item) {
      case "section1Title":
        productdata.map((data) => {
          if (data.id === "section1Title") {
            title = getValueFromLang(data.translations, lang, true);
          }
        });
        return title;
      case "section1ButtonLink":
        productdata.map((data) => {
          if (data.id === "section1ButtonLink") {
            link = data.value;
          }
        });
        return link;
      case "section1Description":
        productdata.map((data) => {
          if (data.id === "section1Description") {
            description = getValueFromLang(data.translations, lang, true);
          }
        });
        return description;
      case "section1ButtonLabel":
        productdata.map((data) => {
          if (data.id === "section1ButtonLabel") {
            content = getValueFromLang(data.translations, lang, true);
          }
        });
        return content;
      case "section1Image":
        productdata.map((data) => {
          if (data.id === "section1Image") {
            src = data.value;
          }
        });
        return src;
    }
  };

  return (
    <Layout className={"main-layout-container"}>
      {state.loaded && (
        <>
          <Layout className="min-h-100vh">
            <Header
              className={
                props.travelInsurance
                  ? "product-detail-header travel-insurance-header-height"
                  : "product-detail-header"
              }
            >
              <HeaderHome productDetail />
              <Outlet />
            </Header>
            <MobileNavbar />

            <Content className={"product-detail-content-container"}>
              {props.searchVIP && (
                <SearchVIPLounge
                  airportsList={props.airportsDuck.airports.response}
                />
              )}
              {props.travelInsurance && (
                <div className={"product-detail-travel-insurance-form-space"} />
              )}
              <HomeServiceCarousel title={t("product.discoverAllServices")} />

              <div className={"product-detail-service-card-container"}>
                <HomeServiceCard
                  title={getValue("section1Title")}
                  src={getValue("section1Image")}
                  alt={getValue("alt")}
                  description={getValue("section1Description")}
                  content={getValue("section1ButtonLabel")}
                  link={getValue("section1ButtonLink")}
                />
              </div>
            </Content>
          </Layout>

          <Footer className={"product-detail-footer"}>
            <Newsletter />
            <FooterHome />
          </Footer>
        </>
      )}
      {!state.loaded && <Loading />}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  airportsDuck: state.airportsDuck,
  productsListDuck: state.productsListDuck,
  languageDuck: state.languageDuck,
  newsletterDataDuck: state.newsletterDataDuck,
  productsDataDuck: state.productsDataDuck,
});

export default connect(mapStateToProps)(ProductDetailLayout);
