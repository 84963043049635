import React, { useRef, useState } from "react";

// STYLES
import './SearchVIPLounge.css';

// COMPONENTS
import FlightSearchInput from "../flightSearchInput/FlightSearchInput";
import CustomButton from "../customButton/CustomButton";
import LoungeCard from "../loungeCard/LoungeCard";

// ASSETS
import DepartureIcon from "../../../assets/images/searchFlight/departure-icon.svg";
import SearchIcon from "../../../assets/images/searchFlight/search-icon.svg";

// REDUX
import { useDispatch } from "react-redux";
import { doSearchLounge } from "../../../redux/actions/actions";

import { useTranslation } from "react-i18next";
import { Col, Row, Image, Spin } from "antd";
import { useFormik } from "formik";



const SearchVIPLounge = (props) => {

  const [state, setState] = useState({
    airport: null,
    lounges: null,
    loading: false
  })

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  let isDown = false;
  let startX;
  let scrollLeft;

  const formikSearchLounge = useFormik({
    initialValues: {
      lounge: null
    },
    onSubmit: (values) => {
      sendSearch(values);
    }
  });

  const sendSearch = async (values) => {
    if (values.lounge) {
      setState({
        ...state,
        loading: true,
        lounges: null
      })

      let data = {
        "airport": values.lounge
      }
      let response = await doSearchLounge(dispatch, data)
      setState({
        ...state,
        lounges: response.response,
        loading: false
      })
    }
  }

  const handleLoungeInputChange = (value) => {
    formikSearchLounge.values.lounge = value;
    setState({
      ...state,
      airport: value
    })
  }

  const searchIcon = () => {
    return (
      <>
        <Image
          src={SearchIcon}
          alt={"search icon"}
          preview={false}
        />
      </>
    )
  }

  const onMouseDown = e => {
    isDown = true;
    ref.current.classList.add('home-service-carousel-active');
    startX = e.pageX - ref.current.offsetLeft;
    scrollLeft = ref.current.scrollLeft;
  };

  const notScrolling = () => {
    isDown = false;
    ref.current.classList.remove('home-service-carousel-active');
  }

  const onMouseMove = e => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX);
    ref.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <Col className={"search-vip-lounge-container"}>
      <div className={"search-vip-lounge-title"}>{t("product.products.VIPLOUNGE.searchLounge")}</div>
      <Row className={"search-vip-lounge-input-container"}>
        <FlightSearchInput
          hideText
          id={"lounge"}
          onChange={handleLoungeInputChange}
          value={formikSearchLounge.values.lounge}
          placeholder={t("home.searchFlights.airport")}
          icon={DepartureIcon}
          iconAlt={t("home.searchFlights.airport")}
          airportsList={props.airportsList}
        />
        <CustomButton
          content={searchIcon()}
          clickCallback={formikSearchLounge.submitForm}
          type={'searchFlight'}
        />
      </Row>
      <div
        className={"search-vip-lounge-cards-container"}
        onMouseDown={onMouseDown}
        onMouseUp={notScrolling}
        onMouseLeave={notScrolling}
        onMouseMove={onMouseMove}
        ref={ref}
      >
        {
          state.loading &&
          <div className={"search-vip-lounge-loader-container"}>
            <Spin size={"large"} />
          </div>
        }
        {
          state.lounges && state.lounges.length > 0 &&
          state.lounges.map((lounge, index) => {
            return (
              <div key={index}>
                <LoungeCard
                  src={lounge.loungeImages[0]}
                  title={lounge.loungeLoungeName}
                  terminal={lounge.loungeTerminal}
                  where={lounge.where}
                  amount={lounge.creditForActivate}
                  currency={lounge.transactionModel.currency}
                  opening={lounge.jsonLounge.OpeningHours}
                  conditions={lounge.loungeConditions}
                  services={lounge.facilities}
                />
              </div>
            )
          })
        }
        {
          state.lounges && state.lounges.length === 0 &&
          <div className={"search-vip-lounge-no-results-text"}>{t("product.products.VIPLOUNGE.noLounges")}</div>
        }
      </div>
    </Col>
  )
}

export default SearchVIPLounge