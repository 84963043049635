import React from "react";

// STYLES
import './ProfileDropdownMenu.css';

// ASSETS
import ProfileIcon from '../../../assets/images/home/user-logo.svg';
import MenuArrow from "../../../assets/images/searchFlight/menu-arrow.svg";

// REDUX
import { connect } from "react-redux";
import { userLogout } from "../../../redux/ducks/UserInfo";

import { Menu, Dropdown, Typography, Row, Image, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

const ProfileDropdownMenu = (props) => {

  const { Text } = Typography;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = (value) => () => {
    switch (value) {
      case "0":
        navigate(`${ENVIRONMENT.ROUTING.SIGNUP_URL}`);
        break;
      case "1":
        navigate(`${ENVIRONMENT.ROUTING.SIGNIN_URL}`);
        break;
      case "2":
        window.location.href = `${t('profile.dropdownLinks.supportHref')}`;
        break;
      case "3":
        navigate(`${ENVIRONMENT.ROUTING.BASE_URL}profile`);
        break;
      case "4":
        navigate(`${ENVIRONMENT.ROUTING.BASE_URL}profile/orders`);
        break;
      case "5":
        dispatch(userLogout());
        navigate(ENVIRONMENT.ROUTING.BASE_URL);
        notification.open({
          message: t("general.logoutConfirmed"),
          top: 120,
          duration: 3,
          className: "product-added-notification-message",
        })
        break;
      default:
        break;
    }
  }

  const menu = (
    props.userInfoDuck.userInfo.token ?
      <Menu className={'home-header-profile-dropdown-menu-container'}>
        <Menu.Item key="3" onClick={handleClick("3")}>
          <Text>{t('profile.dropdownLinks.account')}</Text>
        </Menu.Item>
        <Menu.Item key="4" onClick={handleClick("4")}>
          <Text>{t('profile.dropdownLinks.orders')}</Text>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={handleClick("2")}>
          <Text>{t('profile.dropdownLinks.support')}</Text>
        </Menu.Item>
        <Menu.Item key="5" onClick={handleClick("5")}>
          <Text>{t('profile.dropdownLinks.logout')}</Text>
        </Menu.Item>
      </Menu>
      :
      <Menu className={'home-header-profile-dropdown-menu-container'}>
        <Menu.Item key="0" onClick={handleClick("0")}>
          <Text>{t('profile.dropdownLinks.registerNow')}</Text>
        </Menu.Item>
        <Menu.Item key="1" onClick={handleClick("1")}>
          <Text>{t('profile.dropdownLinks.login')}</Text>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={handleClick("2")}>
          <Text>{t('profile.dropdownLinks.support')}</Text>
        </Menu.Item>
      </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Row
        className={'home-header-profile-dropdown-icons-container'}
        style={props.dark ? { border: '1px solid #EAEAEA' } : {}}
      >
        <Image
          src={ProfileIcon}
          alt={'profile'}
          preview={false}
        />
        <Image
          src={MenuArrow}
          alt={'arrow'}
          preview={false}
        />
      </Row>
    </Dropdown>
  )
}

const mapStateToProps = state => ({
  userInfoDuck: state.userInfoDuck
})

export default connect(mapStateToProps)(ProfileDropdownMenu)