import { useEffect, useRef } from "react";
import { useRoutes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SiteRoutes from "./routes";

export default function App() {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const langRef = useRef(resolvedLanguage);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/cookies/${langRef.current}.js`;

    const iub = document.createElement("script");
    iub.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    iub.async = true;
    iub.charset = "UTF-8";

    document.body.append(script);

    script.onload = () => {
      document.body.append(iub);
    };

    return () => {
      script.remove();
      iub.remove();
    };
  }, []);
  // The useRoutes() hook allows you to define your routes as JavaScript objects
  // instead of <Routes> and <Route> elements. This is really just a style
  // preference for those who prefer to not use JSX for their routes config.
  let element = useRoutes(SiteRoutes);

  return element;
}
