import React, { useEffect, useState } from "react";

// STYLES
import './Promotions.css';

// ASSETS
import Ticket from "../../../assets/images/profile/promo-ticket-color.svg";
import TicketExpired from "../../../assets/images/profile/promo-ticket-black.svg";
import NoPromoImage from "../../../assets/images/profile/promo-no-item.svg";

// COMPONENTS
import SEO from "../../../components/functional_components/SEO/SEO";

import { Col, Image, Typography, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { getPromo } from "../../../redux/actions/actions";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';


const Promotions = () => {

  const { Text, Title } = Typography;
  const { t } = useTranslation();

  const [state, setState] = useState({
    loading: true,
    promo: [],
    expiredPromo: []
  })

  useEffect(() => {
    promoList();
  }, [])

  const promoList = async () => {
    let userId = {};
    let tempUserId = localStorage.getItem('userInfo');
    if (tempUserId) {
      userId = JSON.parse(CryptoAES.decrypt(tempUserId, "MEGAKEY").toString(CryptoENC));
    }
    let userInfo = { "id": userId.id }
    let promoList = await getPromo(userInfo.id);
    if (!promoList) {
      promoList = { response: [] }
    }

    setState({
      ...state,
      promo: promoList.response,
      loading: false
    })
  }

  return (
    <Col style={{ maxWidth: '730px' }}>
      <SEO
        page={"Promotions"}
        url={"profile/promotions"}
      />

      <Col className={'profile-account-title-desktop'}>
        <Title>{t("profile.promo.myPromo")}</Title>
        <div className={"profile-account-title-desktop-line"} />
      </Col>
      {
        state.loading &&
        <Col className={"profile-loader-container"}>
          <Skeleton active />
          <Skeleton active />
        </Col>
      }
      {
        !state.loading &&
        <>
          {
            state.promo.length > 0 &&
            <Col className={"profile-promotions-container"}>
              {
                state.promo.map((promo, i) => {
                  return (
                    <div key={i}>
                      <Col className={"profile-promotions-ticket-container"}>
                        <Image
                          src={Ticket}
                          alt={"Ticket"}
                          preview={false}
                        />

                        <Text className={"profile-promotions-ticket-available-text"}>
                          {t("profile.promo.available")}
                        </Text>

                        <div className={"profile-promotions-ticket-text-container"}>
                          <Text className={"profile-promotions-ticket-discount"}>{promo.totalAmount}</Text>
                          <Text className={"profile-promotions-ticket-title"}>{promo.promotionDescription}</Text>
                          <Text className={"profile-promotions-ticket-description"}>{promo.promotionMessage}</Text>
                        </div>
                      </Col>
                    </div>
                  )
                })
              }
            </Col>
          }
          {
            state.expiredPromo.length > 0 &&
            <Col className={"profile-promotions-container profile-promotions-expired-container"}>
              {
                state.expiredPromo.map((expiredPromo, i) => {
                  return (
                    <div key={i}>
                      <Col className={"profile-promotions-ticket-container"}>
                        <Image
                          src={TicketExpired}
                          alt={"Ticket expired"}
                          preview={false}
                        />
                        <Text className={"profile-promotions-ticket-available-text"}>
                          {t("profile.promo.expired")}
                        </Text>

                        <div className={"profile-promotions-ticket-text-container"}>
                          <Text className={"profile-promotions-ticket-discount profile-promotions-ticket-expired"}>{expiredPromo.totalAmount}</Text>
                          <Text className={"profile-promotions-ticket-title profile-promotions-ticket-expired"}>{expiredPromo.promotionDescription}</Text>
                          <Text className={"profile-promotions-ticket-description profile-promotions-ticket-expired"}>{expiredPromo.promotionMessage}</Text>
                        </div>
                      </Col>
                    </div>
                  )
                })
              }
            </Col>
          }
          {
            state.promo.length === 0 && state.expiredPromo.length === 0 &&
            <Col className={"profile-orders-no-items-image-container"}>
              <Image
                alt={"No items"}
                src={NoPromoImage}
                preview={false}
              />
              <Text>{t("profile.promo.noPromotions")}</Text>
            </Col>
          }
        </>
      }
    </Col >
  )
}

export default Promotions