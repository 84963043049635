const SET_DISCOUNT = 'SET_DISCOUNT'
const INIT_DISCOUNT = 'INIT_DISCOUNT'

export function setDiscount(value) {
  return {
    type: SET_DISCOUNT,
    payload: {
      discount: value
    }
  };
}

export function initDiscount() {
  return {
    type: INIT_DISCOUNT,
    payload: {}
  }
}

const INIT_STATE = {
  discount: {
    code: null,
    amount: 0
  }
}

export default function discountDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_DISCOUNT:
      newState.discount = action.payload.discount;
      break;
    case INIT_DISCOUNT:
      newState.discount = {};
      break;
    default:
      break;
  }
  return newState;
}