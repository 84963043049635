const SET_HOMEDATA = 'SET_HOMEDATA'
const INIT_HOMEDATA = 'INIT_HOMEDATA'

export function setHomeData(value) {
  return {
    type: SET_HOMEDATA,
    payload: {
      homeData: value
    }
  };
}

export function initHomeData() {
  return {
    type: INIT_HOMEDATA,
    payload: {
      homeData: {}
    }
  }
}

const INIT_STATE = {
  homeData: {}
}

export default function homeDataDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_HOMEDATA:
      newState.homeData = action.payload.homeData;
      break;
    case INIT_HOMEDATA:
      newState.homeData = {};
      break;
    default:
      break;
  }
  return newState;
}