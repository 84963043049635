import React from "react";

import { useLocation, Navigate } from "react-router-dom";
import { ENVIRONMENT } from './utils/properties';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

// SCREENS
import SignIn from './screens/SignIn/SignIn'
import SignUp from './screens/SignUp/SignUp';
import SignUpOTP from './screens/SignUp/SignUpOTP'
import SignUpStep2 from "./screens/SignUp/SignUpStep2";
import Home from "./screens/Home/Home";
// import NoMatch from "./screens/NoMatch";
import Provider from "./screens/Provider/Provider";
import FlightsList from "./screens/FlightsList/FlightsList";
import PreCheckout from "./screens/Checkout/PreCheckout/PreCheckout";
import CheckoutServices from "./screens/Checkout/CheckoutServices/CheckoutServices";
import Payment from "./screens/Checkout/Payment/Payment";
import Account from "./screens/Profile/Account/Account";
import Promotions from "./screens/Profile/Promotions/Promotions";
import Orders from "./screens/Profile/Orders/Orders";
import ThankYou from "./screens/ThankYou/ThankYou";
import FaqPage from "./screens/FaqPage/FaqPage";
import ErrorPage from "./screens/ErrorPage/ErrorPage";
import Page404 from "./screens/Page404/Page404";
import ProductDetail from "./screens/ProductDetail/ProductDetail";
import ForgotPassword from "./screens/ForgotPassword/ForgotPassword";
import ResetPassword from "./screens/ForgotPassword/ResetPassword";

// B2B
import B2B_SignIn from "./screens/B2B_SignIn/B2B_SignIn";
import B2B_Home from "./screens/B2B_Home/B2B_Home";
import B2B_Checkout from "./screens/B2B_Checkout/B2B_Checkout";
import B2B_Payment from "./screens/B2B_Payment/B2B_Payment";
import B2B_PaymentMethods from "./screens/B2B_Profile/B2B_PaymentMethods";

// LAYOUTS
import LoginLayout from "./layout/loginLayout/LoginLayout";
import MainLayout from "./layout/mainLayout/MainLayout";
import CheckOutLayout from "./layout/checkOutLayout/CheckOutLayout";
import ProfileLayout from "./layout/profileLayout/ProfileLayout";
import FaqLayout from "./layout/faqLayout/FaqLayout";
import ProductDetailLayout from "./layout/productDetailLayout/ProductDetailLayout";




export default [
  {
    path: `${ENVIRONMENT.ROUTING.SIGNIN_URL}`,
    element: <LoginLayout page={"signin"} />,
    children: [
      { index: true, element: <SignIn /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.SIGNUP_URL}`,
    element: <LoginLayout page={"signup"} />,
    children: [
      { index: true, element: <SignUp /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.SIGNUP_URL}/OTP`,
    element: <LoginLayout page={"signup"} />,
    children: [
      { index: true, element: <SignUpOTP /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.SIGNUP_URL}/step2`,
    element: <LoginLayout page={"signup"} />,
    children: [
      { index: true, element: <SignUpStep2 /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.SIGNUP_URL}/forgot-password`,
    element: <LoginLayout />,
    children: [
      { index: true, element: <ForgotPassword /> },
      {
        path: `${ENVIRONMENT.ROUTING.SIGNUP_URL}/forgot-password/reset-password`,
        element: <ResetPassword />
      }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}`,
    element: <MainLayout />,
    children: [
      { index: true, element: <Home /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}provider`,
    element: <Provider />,
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}flights-list`,
    children: [
      { index: true, element: <FlightsList /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}pre-checkout`,
    element: <CheckOutLayout />,
    children: [
      { index: true, element: <PreCheckout /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}services-checkout`,
    element: <RequireCartItems><RequireB2CAuth><CheckOutLayout step={2} /></RequireB2CAuth></RequireCartItems>,
    children: [
      { index: true, element: <CheckoutServices /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}payment`,
    element: <RequireCartItems><RequireB2CAuth><CheckOutLayout step={3} /></RequireB2CAuth></RequireCartItems>,
    children: [
      { index: true, element: <Payment /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}profile`,
    element: <RequireAuth><ProfileLayout /></RequireAuth>,
    children: [
      { index: true, element: <Account /> },
      {
        path: `${ENVIRONMENT.ROUTING.BASE_URL}profile/orders`,
        element: <Orders />
      },
      {
        path: `${ENVIRONMENT.ROUTING.BASE_URL}profile/promotions`,
        element: <Promotions />
      },
      {
        path: `${ENVIRONMENT.ROUTING.BASE_URL}profile/paymentMethods`,
        element: <B2B_PaymentMethods />
      }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}thank-you`,
    element: <ThankYou />,
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}faqs`,
    element: <FaqLayout />,
    children: [
      { index: true, element: <FaqPage /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}error`,
    element: <ErrorPage />
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}LLC-detail`,
    element: <ProductDetailLayout product={"llc"} />,
    children: [
      { index: true, element: <ProductDetail product={'LLC'} /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}DrTravel-detail`,
    element: <ProductDetailLayout product={'drtravel'} />,
    children: [
      { index: true, element: <ProductDetail product={'TLDRFM'} /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}VIPLOUNGE-detail`,
    element: <ProductDetailLayout searchVIP={true} product={'viplounge'} />,
    children: [
      { index: true, element: <ProductDetail product={'VIPLOUNGE'} /> }
    ]
  },
  // {
  //   path: `${ENVIRONMENT.ROUTING.BASE_URL}travel-insurance`,
  //   element: <ProductDetailLayout travelInsurance={true} product={'healthtravel'} />,
  //   children: [
  //     { index: true, element: <ProductDetail product={'TravelInsurance'} /> }
  //   ]
  // },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}B2B/signin`,
    element: <LoginLayout />,
    children: [
      { index: true, element: <B2B_SignIn /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}B2B/home`,
    element: <RequireB2BAuth><CheckOutLayout /></RequireB2BAuth>,
    children: [
      { index: true, element: <B2B_Home /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}B2B/checkout`,
    element: <RequireCartItems><RequireB2BAuth><CheckOutLayout step={2} /></RequireB2BAuth></RequireCartItems>,
    children: [
      { index: true, element: <B2B_Checkout /> }
    ]
  },
  {
    path: `${ENVIRONMENT.ROUTING.BASE_URL}B2B/payment`,
    element: <RequireCartItems><RequireB2BAuth><CheckOutLayout step={3} /></RequireB2BAuth></RequireCartItems>,
    children: [
      { index: true, element: <B2B_Payment /> }
    ]
  },
  {
    path: "*",
    element: <Page404 />
  }
];


function RequireAuth({ children }) {
  let localUserInfo = localStorage.getItem('userInfo');
  let auth = false;
  if (localUserInfo) {
    if (JSON.parse(CryptoAES.decrypt(localUserInfo, "MEGAKEY").toString(CryptoENC)).token) {
      auth = true;
    }
  }
  const location = useLocation();

  if (!auth) {
    return <Navigate to={ENVIRONMENT.ROUTING.SIGNIN_URL} state={{ from: location }} />;
  }
  return children;
}

function RequireB2CAuth({ children }) {
  let localUserInfo = localStorage.getItem('userInfo');
  let auth = false;
  if (localUserInfo) {
    if (JSON.parse(CryptoAES.decrypt(localUserInfo, "MEGAKEY").toString(CryptoENC)).token && JSON.parse(CryptoAES.decrypt(localUserInfo, "MEGAKEY").toString(CryptoENC)).type === "B2C") {
      auth = true;
    }
  }
  const location = useLocation();

  if (!auth) {
    return <Navigate to={ENVIRONMENT.ROUTING.SIGNIN_URL} state={{ from: location }} />;
  }
  return children;
}

function RequireB2BAuth({ children }) {
  let localUserInfo = localStorage.getItem('userInfo');
  let auth = false;
  if (localUserInfo) {
    if (JSON.parse(CryptoAES.decrypt(localUserInfo, "MEGAKEY").toString(CryptoENC)).token && JSON.parse(CryptoAES.decrypt(localUserInfo, "MEGAKEY").toString(CryptoENC)).type !== "B2C") {
      auth = true;
    }
  }

  const location = useLocation();

  if (!auth) {
    return <Navigate to={`${ENVIRONMENT.ROUTING.BASE_URL}B2B/signin`} state={{ from: location }} />;
  }
  return children;
}

function RequireCartItems({ children }) {
  const localUserCart = localStorage.getItem('services');
  let empty = true;
  if (localUserCart) {
    if (JSON.parse(localUserCart).length > 0) {
      empty = false;
    }
  }

  if (empty) {
    return <Navigate to={`${ENVIRONMENT.ROUTING.BASE_URL}`} />;
  }
  return children;
}