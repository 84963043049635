import React, { useEffect, useState } from "react";

// STYLES
import './FaqLayout.css';

// COMPONENTS
import MobileNavbar from "../../components/hooks_components/mobileNavbar/MobileNavbar";
import FooterHome from "../../components/functional_components/footerHome/FooterHome";
import CheckoutHeader from "../../components/functional_components/checkoutHeader/CheckoutHeader";

import { Layout, Spin, Col, Skeleton } from "antd";
import { Outlet } from "react-router";

//REDUX
import { useDispatch, connect } from "react-redux";
import { getFaqsData, getProductsList } from "../../redux/actions/actions";

const FaqLayout = (props) => {

  const { Header, Content, Footer } = Layout;
  const dispatch = useDispatch();

  const [state, setState] = useState({
    loading: true
  })

  useEffect(() => {
    getFaqsList();
  }, [])

  const getFaqsList = async () => {
    if (Object.keys(props.faqsDuck.faqs).length === 0) {
      await getFaqsData(dispatch);
    }

    if (Object.keys(props.productsListDuck.productsList).length === 0) {
      await getProductsList(dispatch);
    }

    setState({
      ...state,
      loading: false
    })
  }

  return (
    <Layout>

      <Header className={'faq-layout-header-container'}>
        <CheckoutHeader
          faqsPage
          color={'var(--main-black)'}
        />
      </Header>

      <Content className={'faqs-layout-content-container'}>
        {
          state.loading &&
          <Col className={"faq-layout-content-spin-container"}>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Col>
        }
        {
          !state.loading &&
          <Outlet />
        }

      </Content>

      <MobileNavbar />

      <Footer className={"profile-layout-footer-container"}>
        <FooterHome loading={state.loading} />
      </Footer>

    </Layout>
  )
}

const mapStateToProps = state => ({
  faqsDuck: state.faqsDuck,
  productsListDuck: state.productsListDuck
})

export default connect(mapStateToProps)(FaqLayout)