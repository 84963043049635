import React from "react";

// STYLES
import './ProfileHeader.css';

// COMPONENTS
import GoBackButton from '../goBackButton/GoBackButton';
import ProfileHeaderNavbar from "../profileHeaderNavbar/ProfileHeaderNavbar";

import { ENVIRONMENT } from "../../../utils/properties";
import { Col } from "antd";

const ProfileHeader = () => {

  return (
    <Col className={'profile-header-container'}>
      <GoBackButton
        color={'black'}
        to={`${ENVIRONMENT.ROUTING.BASE_URL}`}
      />
      <ProfileHeaderNavbar />
    </Col>
  )
}

export default ProfileHeader