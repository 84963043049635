import React from "react";

// STYLES
import "./LangModalContent.css";

// REDUX
import { setLanguage } from "../../../redux/ducks/Language";
import { connect, useDispatch } from "react-redux";
import { setCurrency } from "../../../redux/ducks/Currency";

import { Typography } from "antd";
import { switchLang } from "../../../i18n/i18n-config";
import { useLocation } from "react-router-dom";
import { doUpdateData, getUserData } from "../../../redux/actions/actions";

const LangModalContent = (props) => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const updateUserData = async (type, value) => {
    let userData = props.userDataDuck.userData;
    if (Object.keys(props.userInfoDuck.userInfo).length > 0) {
      if (Object.keys(props.userDataDuck.userData).length === 0) {
        userData = await getUserData(dispatch);
      }
    }

    if (userData && !userData.error) {
      let newUserData = {
        address: userData.data.userData.address,
        birthDate: userData.data.userData.birthDate,
        city: userData.data.userData.city,
        country: userData.data.userData.country,
        email: userData.data.email,
        gender: userData.data.userData.gender,
        id: userData.data.id,
        marketingPolicy: userData.data.userLegal.marketingPolicy,
        name: userData.data.name,
        phone: userData.data.phone,
        prefixPhone: userData.data.prefixPhone,
        privacyPolicy: userData.data.userLegal.privacyPolicy,
        source: userData.data.source,
        surname: userData.data.surname,
        thirtyPartPolicy: userData.data.userLegal.thirtyPartPolicy,
        type: userData.data.type,
        username: userData.data.username,
        zipCode: userData.data.userData.zipCode,
        language: userData.data.userSettings.language,
        currency: userData.data.userSettings.currency
      }
      if (type === "lang") {
        newUserData.language = value;
      }
      if (type === "curr") {
        newUserData.currency = value;
      }
      await doUpdateData(newUserData)
    }
  }

  const languages = [
    {
      language: "Italiano",
      country: "Italia",
      value: "IT",
    },
    {
      language: "English",
      country: "United States",
      value: "EN",
    },
    {
      language: "Português",
      country: "Portugal",
      value: "PT",
    },
    {
      language: "Español",
      country: "España",
      value: "ES",
    },
    {
      language: "русский",
      country: "Россия",
      value: "RU",
    },
    {
      language: "Français",
      country: "France",
      value: "FR",
    },
  ];

  const currency = [
    {
      currencySymbol: "€",
      currency: "Euro",
      value: "EUR",
    },
    {
      currencySymbol: "$",
      currency: "Dollar",
      value: "USD",
    },
  ];

  const langSelect = (i, val) => async () => {
    let lang = document.getElementsByClassName(
      "modal-lang-content-lang-container"
    );
    let curr = document.getElementsByClassName(
      "modal-lang-content-currency-container"
    );

    if (val === "lang") {
      for (let i = 0; i < lang.length; i++) {
        lang[i].classList.remove("modal-lang-content-lang-container-active");
      }

      lang[i].classList.add("modal-lang-content-lang-container-active");
      await updateUserData("lang", languages[i].value.toLowerCase())
      await switchLang(languages[i].value);
      dispatch(setLanguage(languages[i].value));
      window.location.href = "/" + languages[i].value.toLowerCase() + pathname;
    } else {
      for (let i = 0; i < curr.length; i++) {
        curr[i].classList.remove(
          "modal-lang-content-currency-container-active"
        );
      }

      curr[i].classList.add("modal-lang-content-currency-container-active");
      await updateUserData("curr", currency[i].value)
      dispatch(setCurrency(currency[i].value));
    }
  };

  return (
    <>
      <div>
        <Title className={"modal-lang-content-title"}>{props.title}</Title>
      </div>
      <div className={"modal-lang-content-container"}>
        {props.type === "lang" &&
          languages.map((lang, i) => {
            return (
              <div
                key={i}
                className={
                  props.defaultValue === lang.value
                    ? "modal-lang-content-lang-container modal-lang-content-lang-container-active"
                    : "modal-lang-content-lang-container"
                }
                onClick={langSelect(i, "lang")}
              >
                <div>{lang.language}</div>
                <div>{lang.country}</div>
              </div>
            );
          })}
        {props.type === "currency" &&
          currency.map((currency, i) => {
            return (
              <div
                key={i}
                className={
                  props.defaultValue === currency.value
                    ? "modal-lang-content-currency-container modal-lang-content-currency-container-active"
                    : "modal-lang-content-currency-container"
                }
                onClick={langSelect(i, "curr")}
              >
                <div>{currency.currencySymbol}</div>
                <div>{currency.currency}</div>
              </div>
            );
          })}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  userDataDuck: state.userDataDuck,
  userInfoDuck: state.userInfoDuck
})

export default connect(mapStateToProps)(LangModalContent);
