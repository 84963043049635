import React from "react";

// STYLES
import "./Home.css";

// COMPONENTS
import HomeServiceCard from "../../components/functional_components/homeServiceCard/HomeServiceCard";
import HomeNoStress from "../../components/functional_components/homeNoStress/HomeNoStress";
import Faq from "../../components/functional_components/faq/Faq";
import HomeServiceCarousel from "../../components/functional_components/homeServiceCarousel/HomeServiceCarousel";
import HomeServiceInfo from "../../components/functional_components/homeServiceInfo/HomeServiceInfo";
import FlightSearch from "../../components/hooks_components/flightSearch/FlightSearch";
import SEO from "../../components/functional_components/SEO/SEO";

import { Layout, Col } from "antd";
import { connect } from "react-redux";
import { get } from "lodash";
import { setLocale } from "../../utils/utilities";

const Home = (props) => {

  const getComponentData = (id) => {
    let result;
    props.homeDataDuck.homeData.data.components.map((component) => {
      if (component.id === id) {
        if (component.type === "image" || component.type === "link") {
          return (result = component.value);
        }
        component.translations.map((translation) => {
          if (
            translation.lang ===
            props.languageDuck.currentLanguage.toLowerCase()
          ) {
            result = translation.text;
          }
        });
      }
    });
    return result;
  };

  return (
    <Layout className={"home-layout-container"}>

      <SEO
        page={"Home"}
      />

      <FlightSearch locale={setLocale(props.languageDuck.currentLanguage)} />

      <Col className={"home-service-info-responsive-container"}>
        {get(props.productsListDuck.productsList, "data", []).map(
          (product, index) => {
            if (product.b2c) {
              return (
                <div key={index}>
                  <HomeServiceInfo
                    code={product.code}
                    title={product.name}
                    badge={product.iconImage}
                    description={product.introDescription}
                  />
                </div>
              );
            }
          }
        )}
      </Col>

      <HomeServiceCarousel title={getComponentData("carouselTitle")} />

      <HomeNoStress
        title={getComponentData("appBannerTitle")}
        subtitle={getComponentData("appBannerSubtitle")}
        src={getComponentData("appBannerImage")}
        label={getComponentData("appBannerButtonLabel")}
        link={getComponentData("appBannerButtonLink")}
      />

      <HomeServiceCard
        title={getComponentData("section1Title")}
        src={getComponentData("section1Image")}
        description={getComponentData("section1Description")}
        content={getComponentData("section1ButtonLabel")}
        link={getComponentData("section1ButtonLink")}
        reverse={true}
      />
      {/* <HomeServiceCard
        title={getComponentData("section2Title")}
        src={getComponentData("section2Image")}
        description={getComponentData("section2Description")}
        content={getComponentData("section2ButtonLabel")}
        link={getComponentData("section2ButtonLink")}
      /> */}
      <Faq homeData={props.homeDataDuck.homeData.data.components} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  productsListDuck: state.productsListDuck,
  homeDataDuck: state.homeDataDuck,
  languageDuck: state.languageDuck
});

export default connect(mapStateToProps)(Home);
