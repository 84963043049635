module.exports = {
  DEPLOY_ENV_NAME: "DEVELOPMENT",
  // API_REST_BE:
  //   "http://ec2-15-161-251-53.eu-south-1.compute.amazonaws.com:8060/",
  API_REST_BE: "https://dev-shop.sostravel.com/api/",
  PROVIDER_BASE_URL: "http://localhost:3000/",
  ROUTING: {
    SIGNIN_URL: "/signin",
    SIGNUP_URL: "/signup",
    BASE_URL: "/",
    B2B_HOME_URL: "/B2B/home",
  },
  CDN_BASE_PATH: 'https://dev-sostravel-media.azureedge.net/media'
};
