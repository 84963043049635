import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../utils/properties";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { notification } from "antd";

// REDUX
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/ducks/UserInfo";
import { getUserData } from "../../redux/actions/actions";
import { t } from "i18next";



const Provider = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let pathname = null;

  useEffect(() => {
    const search = location.search;
    const token = new URLSearchParams(search).get("token")
    pathname = new URLSearchParams(search).get("pathname")

    if (token) {
      if (token === "CONFIRM") {
        navigate(`${ENVIRONMENT.ROUTING.SIGNUP_URL}/otp`);
      } else {
        dispatch(setUserInfo({
          token: token
        }))

        getData(token);
      }
    } else {
      notification.open({
        message: t("signin.providerAccessError"),
        top: 120,
        duration: 2,
        className: "product-added-notification-message error-notification-message",
      })
      navigate(`${ENVIRONMENT.ROUTING.SIGNIN_URL}`);
    }

  }, [])

  const getData = async (value) => {
    let data = await getUserData(dispatch);

    let newData = {
      "token": value,
      "id": data.data.id,
      "email": data.data.email,
      "name": data.data.name,
      "surname": data.data.surname,
      "type": data.data.type
    }
    dispatch(setUserInfo(newData))

    if(pathname !== "null") {
      return navigate(`${pathname}`)
    }
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}`);
  }

  return (
    <div className='centered min-h-100vh'>
      <Spin size='large' />
    </div>
  )
}

export default Provider;