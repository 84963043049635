import React from "react";

// COMPONENTS
import CustomButton from "../customButton/CustomButton";

import { Image, Row } from "antd";

const ProviderButton = (props) => {

	const content = () => {
		return (
			<Row className={'provider-button-content'}>
				<Image
					src={props.logo}
					alt={'logo social'}
					preview={false}
				/>
				{props.label}
			</Row>
		)
	}

	return (
		<div className='mv-15'>
			<CustomButton
				type={props.fb ? 'provider-fb' : props.apple ? 'provider-apple' : 'provider'}
				content={content()}
				clickCallback={props.callback}
				href={props.href}
			/>
		</div>
	)
}

export default ProviderButton