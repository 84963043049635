import React, { useState } from "react";

// STYLES 
import './LoungeCard.css';

// ASSETS
import Arrow from "../../../assets/images/homeServicesCarousel/left-arrow.svg";
import AirCond from "../../../assets/images/loungeServices/vip-lounge-service-air_cond.svg";
import Alcohol from "../../../assets/images/loungeServices/vip-lounge-service-alcohol.svg";
import Conference from "../../../assets/images/loungeServices/vip-lounge-service-conference.svg";
import Disable from "../../../assets/images/loungeServices/vip-lounge-service-disable.svg";
import Dmc from "../../../assets/images/loungeServices/vip-lounge-service-dmc.svg";
import Fax from "../../../assets/images/loungeServices/vip-lounge-service-fax.svg";
import FltInfo from "../../../assets/images/loungeServices/vip-lounge-service-flt_info.svg";
import Internet from "../../../assets/images/loungeServices/vip-lounge-service-internet.svg";
import NewsMag from "../../../assets/images/loungeServices/vip-lounge-service-news_mag.svg";
import NoDmc from "../../../assets/images/loungeServices/vip-lounge-service-no_dmc.svg";
import NoSmoke from "../../../assets/images/loungeServices/vip-lounge-service-nosmoke.svg";
import Refresh from "../../../assets/images/loungeServices/vip-lounge-service-refresh.svg";
import Telephone from "../../../assets/images/loungeServices/vip-lounge-service-telephone.svg";
import Tv from "../../../assets/images/loungeServices/vip-lounge-service-tv.svg";
import Wifi from "../../../assets/images/loungeServices/vip-lounge-service-wifi.svg";
import Shower from "../../../assets/images/loungeServices/vip-lounge-service-shower.svg";

// REDUX
import { useDispatch, connect } from "react-redux";
import { setServices } from "../../../redux/ducks/Services";

import { Image, Col, Row, Button, Dropdown, Menu, notification } from "antd";
import { useTranslation } from "react-i18next";

const LoungeCard = (props) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    whereMore: false,
    timeMore: false,
    conditionsMore: false
  })

  const getPrice = () => {
    let price;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === "VIPLOUNGE") {
        price = product.prices;
      }
    });
    return price;
  }

  const servicesData = [
    { label: "Air cond", src: AirCond },
    { label: "Alcohol", src: Alcohol },
    { label: "Cnn", src: Tv },
    { label: "Conferences", src: Conference },
    { label: "Disable", src: Disable },
    { label: "Dmc pass", src: Dmc },
    { label: "Fax", src: Fax },
    { label: "Time table", src: FltInfo },
    { label: "Internet", src: Internet },
    { label: "News Mag", src: NewsMag },
    { label: "No Dmc pass", src: NoDmc },
    { label: "No smoke", src: NoSmoke },
    { label: "Refresh", src: Refresh },
    { label: "Shower", src: Shower },
    { label: "Tel", src: Telephone },
    { label: "Tv", src: Tv },
    { label: "Wifi", src: Wifi }
  ]

  const services = (
    <Menu>
      {
        props.services.map((service) => {
          if (service.active) {
            return servicesData.map((serviceData, i) => {
              if (service.label === serviceData.label) {
                return (
                  <Menu.Item key={i} className={"lounge-card-service-element"}>
                    <Image
                      src={serviceData.src}
                      preview={false}
                      alt={serviceData.label}
                    /><div>{serviceData.label}</div>
                  </Menu.Item>
                )
              }
            })
          }
        })
      }
    </Menu>
  );

  const handleClick = () => {

    let servicesObj = props.servicesDuck.services;
    if (servicesObj.length > 0) {
      let objExist = false;
      let objIndex = null;

      servicesObj.map((item, index) => {
        if (item.name === "VIPLOUNGE") {
          objExist = true;
          objIndex = index;
        }
      })

      if (objExist) {
        servicesObj[objIndex].value += 1;
      } else {
        servicesObj.push({
          name: "VIPLOUNGE",
          value: 1,
          prices: getPrice()
        })
      }
    } else {
      servicesObj.push({
        name: "VIPLOUNGE",
        value: 1,
        prices: getPrice()
      })
    }

    notification.open({
      message: t("product.productAdded"),
      top: 120,
      duration: 3,
      className: "product-added-notification-message",
    })

    dispatch(setServices(servicesObj))
  }

  const readMore = (value) => () => {
    switch (value) {
      case ("where"):
        setState({
          ...state,
          whereMore: !state.whereMore
        })
        break;
      case ("time"):
        setState({
          ...state,
          timeMore: !state.timeMore
        })
        break;
      case ("conditions"):
        setState({
          ...state,
          conditionsMore: !state.conditionsMore
        })
        break;
      default:
        return;
    }
  }

  return (
    <Col className={"lounge-card-container"}>
      <Col className={'home-service-carousel-card-container'}>
        <Image
          src={props.src}
          alt={props.cardTitle}
          className={'home-service-carousel-card-image'}
          preview={false}
        />

        <Col className={'home-service-carousel-card-data-container'}>
          <Row className={"flex-sb"}>
            <div className={"lounge-card-terminal-title"}>{props.title}</div>
            <Dropdown overlay={services} overlayClassName={"lounge-card-services-dropdown"}>
              <a onClick={e => e.preventDefault()} className={"lounge-card-services-title"}>
                {t("product.products.VIPLOUNGE.card.services")}
                <Image
                  src={Arrow}
                  alt={"arrow"}
                  className={"lounge-card-services-arrow-image"}
                  preview={false}
                /></a>
            </Dropdown>
          </Row>
          <div className={"lounge-card-labels"}>{t("product.products.VIPLOUNGE.card.where")}</div>
          <div
            className={state.whereMore ? "lounge-card-info lounge-card-info-open" : "lounge-card-info"}
            onClick={readMore("where")}
          >
            <span className={"lounge-card-conditions-info-style"}>
              {props.terminal ? `${props.terminal} - ` : null}{props.where}
            </span>
          </div>
          <div className={"lounge-card-labels"}>{t("product.products.VIPLOUNGE.card.time")}</div>
          <div
            className={state.timeMore ? "lounge-card-info lounge-card-info-open" : "lounge-card-info"}
            onClick={readMore("time")}
          >
            <span className={"lounge-card-conditions-info-style"}>
              {props.opening}
            </span>
          </div>
          <div className={"lounge-card-labels"}>{t("product.products.VIPLOUNGE.card.conditions")}</div>
          <div
            className={state.conditionsMore ? "lounge-card-conditions-info lounge-card-conditions-info-open" : "lounge-card-conditions-info"}
            onClick={readMore("conditions")}
          >
            <span className={"lounge-card-conditions-info-style"}>
              {props.conditions}
            </span>
          </div>
          <div className={"lounge-card-price"}>{props.currency === "EUR" ? "€" : "$"} {props.amount}</div>
        </Col>
      </Col>
      <Button
        className={'home-service-carousel-button'}
        onClick={handleClick}
      >
        +
      </Button>
    </Col>
  )
}

const mapStateToProps = state => ({
  servicesDuck: state.servicesDuck,
  productsListDuck: state.productsListDuck
})

export default connect(mapStateToProps)(LoungeCard)