import React, { useEffect, useState } from "react";

// STYLES
import "./CartSiderMenu.css";

// COMPONENTS
import CustomButton from "../customButton/CustomButton";
import CheckoutServiceCard from "../checkoutServiceCard/CheckoutServiceCard";

// ASSETS
import badge from "../../../assets/images/homeServicesCarousel/luggage-logo.svg";
import logoDark from "../../../assets/images/logo/logo-sos-travel-gray.svg";
import emptyCart from "../../../assets/images/checkout/cart-no-item.svg";

// REDUX
import { getProductsList } from "../../../redux/actions/actions";
import { useDispatch, connect } from "react-redux";
import { setCart } from "../../../redux/ducks/Cart";

import { Button, Col, Row, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";
import { useTranslation } from "react-i18next";
import { getValueFromLang } from "../../../utils/utilities";
import { formatValue } from "../../../utils/utilities";
import { get } from "lodash";

const CartSiderMenu = (props) => {
  const [state, setState] = useState({
    loading: true,
    availableServices: [],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    let allServices = [];
    if (Object.keys(props.productsListDuck.productsList).length === 0) {
      allServices = await getProductsList(dispatch);
    } else {
      allServices = props.productsListDuck.productsList;
    }

    let userType =
      Object.keys(props.userInfoDuck.userInfo).length > 0
        ? props.userInfoDuck.userInfo.type?.toLowerCase()
        : "b2c";
    let newAvailableServices = [];

    get(allServices, "data", []).map((service) => {
      let formatUserType = "b2c";
      if (userType !== "b2c") {
        formatUserType = "b2b";
      }
      if (service[formatUserType]) {
        newAvailableServices.push({
          name: service.name,
          prices: service.prices,
          subtitle: service.subtitle,
          code: service.code,
        });
      }
    });

    setState({
      ...state,
      loading: false,
      availableServices: newAvailableServices,
    });
  };

  const cartValue = () => {
    let value = 0;
    if (props.servicesDuck.services.length > 0) {
      props.servicesDuck.services.map((service) => {
        value = value + service.value;
      });
    }
    return value;
  };

  const handleClick = () => {
    props.callback();
  };

  const totAmount = () => {
    let result = 0;
    let servicePrice;
    props.servicesDuck.services.map((service) => {
      service.prices?.map((price) => {
        if (price.currency === props.currencyDuck.currency) {
          servicePrice = price.price;
        }
      });
      result = result + service.value * servicePrice;
    });
    return result.toFixed(2);
  };

  const goToCheckout = () => {
    dispatch(setCart(false));
    let path = `${ENVIRONMENT.ROUTING.BASE_URL}services-checkout`;
    if (Object.keys(props.userInfoDuck.userInfo).length > 0) {
      if (props.userInfoDuck.userInfo.type.toLowerCase() !== "b2c") {
        path = `${ENVIRONMENT.ROUTING.BASE_URL}B2B/checkout`;
      }
    }
    navigate(path);
  };

  return (
    <Col
      className={
        props.cartDuck.cart ? "cart-sider-menu" : "cart-sider-menu-invisible"
      }
    >
      <Image
        src={logoDark}
        alt={"logo"}
        className={"cart-sider-logo"}
        preview={false}
      />
      {cartValue() > 0 && !state.loading && (
        <Col className={"cart-sider-content-container"}>
          <Row className={"cart-sider-content-header"}>
            <Row>
              {t("siderMenu.items")} {cartValue()}
            </Row>
            <Button
              className={"cart-sider-content-close-button"}
              onClick={handleClick}
            >
              +
            </Button>
          </Row>
          <Col className={"cart-sider-content-products-container"}>
            {props.servicesDuck.services.map((product) => {
              return state.availableServices.map((service, index) => {
                if (service.code === product.name) {
                  return (
                    <Col key={index}>
                      <CheckoutServiceCard
                        cardCode={service.code}
                        cardTitle={getValueFromLang(
                          service.name,
                          props.languageDuck.currentLanguage
                        )}
                        cardSubtitle={getValueFromLang(
                          service.subtitle,
                          props.languageDuck.currentLanguage
                        )}
                        priceForItem={service.prices.map((price, i) => {
                          if (price.currency === props.currencyDuck.currency) {
                            return (
                              <span key={i}>{formatValue(price.price)}</span>
                            );
                          }
                        })}
                        badge={badge}
                        noDescription
                        siderMenu
                        currency={
                          props.currencyDuck.currency === "EUR" ? "€" : "$"
                        }
                      />
                    </Col>
                  );
                }
              });
            })}
          </Col>
          <Row className={"cart-sider-content-amount-container"}>
            <Row>{t("siderMenu.tot")}</Row>
            <Row>
              {props.currencyDuck.currency === "EUR" ? "€" : "$"}
              {formatValue(totAmount())}
            </Row>
          </Row>
          <Row className={"cart-sider-content-button-checkout-container"}>
            <CustomButton
              content={t("checkout.goToCheckout")}
              clickCallback={goToCheckout}
            />
          </Row>
        </Col>
      )}
      {cartValue() === 0 && (
        <Col className={"cart-sider-content-empty-cart-container"}>
          <Image
            src={emptyCart}
            alt={"empty cart"}
            preview={false}
            className={"cart-sider-content-empty-cart-image"}
          />
          <Row className={"cart-sider-content-empty-cart-text"}>
            {t("siderMenu.noItems")}
          </Row>
        </Col>
      )}
    </Col>
  );
};

const mapStateToProps = (state) => ({
  servicesDuck: state.servicesDuck,
  productsListDuck: state.productsListDuck,
  userInfoDuck: state.userInfoDuck,
  currencyDuck: state.currencyDuck,
  languageDuck: state.languageDuck,
  cartDuck: state.cartDuck,
});

export default connect(mapStateToProps)(CartSiderMenu);
