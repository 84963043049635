import React, { useState } from "react";

// STYLES
import "./PrefixPhoneMenu.css";
import "react-phone-input-2/lib/bootstrap.css";

// REDUX
import { connect } from "react-redux";

import PhoneInput from "react-phone-input-2";

const PrefixPhoneMenu = (props) => {
  const [state, setState] = useState({
    value: props.userDataDuck.userData
      ? props.userDataDuck.userData.data.prefixPhone
      : "+39",
    country: props.userDataDuck.userData
      ? props.userDataDuck.userData.data.prefixPhoneCountry?.toLowerCase()
      : null,
  });

  const handleChange = (value, { countryCode }) => {
    document.activeElement.blur();
    let newValue = `+${value}`;
    setState({
      ...state,
      value: newValue,
      country: countryCode,
    });
    props.handleChangeCallback(newValue, countryCode.toUpperCase());
  };

  const handleKeyDown = () => {
    document.activeElement.blur();
  };

  const handleClassName = () => {
    if (props.profile && !props.disabled) {
      return "prefix-phone-container prefix-phone-container-profile";
    } else if (props.profile && props.disabled) {
      return "prefix-phone-container prefix-phone-container-profile prefix-phone-container-profile-disabled";
    }
    return "prefix-phone-container";
  };

  return (
    <div>
      <PhoneInput
        placeholder={"+39"}
        country={state.country}
        value={state.value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        containerClass={handleClassName()}
        countryCodeEditable={false}
        disabled={props.disabled}
        enableSearch={true}
        inputClass={"prefix-phone-search"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDataDuck: state.userDataDuck,
});

export default connect(mapStateToProps)(PrefixPhoneMenu);
