import React, { useEffect, useState } from "react";

// STYLES
import './Faq.css';

import { Typography, Collapse } from "antd";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";
import { getValueFromLang } from "../../../utils/utilities";
import { connect } from "react-redux";

const Faq = (props) => {

  const { Title, Text } = Typography;
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    faqs: [],
    faqsBg: null,
  })

  let newFaqs = [];

  useEffect(() => {
    let faqsBg;
    for (let i = 0; i < 20; i++) {
      let question;
      let answer;
      
      props.homeData.map((data) => {
        if (data.id === "faqBannerImage") {
          faqsBg = data.value;
        }
        if (data.id === `faq${i}Question`) {
          question = getValueFromLang(data.translations, props.languageDuck.currentLanguage, true)
        }
        if (data.id === `faq${i}Answer`) {
          answer = getValueFromLang(data.translations, props.languageDuck.currentLanguage, true)
        }
      })
      if (question && answer) {
        newFaqs.push({
          "q": question,
          "a": answer
        })
      }
    }

    setState({
      ...state,
      faqs: newFaqs,
      faqsBg: faqsBg
    })

  }, [])

  const faqsRedirect = () => {
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}faqs`);
  }

  return (
    <div className={'home-faq-container'}>
      <Title className={'home-faq-title'}>{t("home.FAQ.title")}</Title>

      <Collapse defaultActiveKey={0} accordion>
        {
          state.faqs.map((faq, key) => {
            return (
              <Panel key={key} header={faq.q} className={'home-faq-panel'}>
                {faq.a}
              </Panel>
            )
          })
        }
      </Collapse>
      <Text className={'home-faq-more-text'} onClick={faqsRedirect}><span className={'home-faq-more-symbol'}>+</span><u>{t("home.FAQ.findMore")}</u></Text>
      <div
        className={'home-faq-desktop-image'}
        style={{ backgroundImage: `url(${state.faqsBg})` }}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(Faq)



