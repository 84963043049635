import React, { useEffect, useState } from "react";

// STYLES
import "./B2B_Payment.css";

// COMPONENTS
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import PaymentMethodCard from "../../components/functional_components/paymentMethodCard/PaymentMethodCard";

// REDUX
import { b2bGetPaymentMethods, doPayment } from "../../redux/actions/actions";
import { connect, useDispatch } from "react-redux";

import { Col, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../utils/properties";

const B2B_Payment = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: true,
    paymentMethods: [],
    selected: 0,
    paymentLoading: false
  })

  useEffect(() => {
    getCards();
  }, [])

  const getCards = async () => {
    let result = await b2bGetPaymentMethods();

    if (result) {
      setState({
        ...state,
        paymentMethods: result.data,
        loading: false
      })
    }
  }

  const selectMethod = (i) => () => {
    setState({
      ...state,
      selected: i
    })
  }

  const getServiceId = (service) => {
    let id;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service.name) {
        id = product.id;
      }
    })
    return id;
  }

  const confirmMethod = async () => {
    let cartItems = [];
    props.servicesDuck.services.map((service) => {
      let item;
      switch (service.name) {
        case ("LLC"):
          item = {
            productCode: service.name,
            productId: getServiceId(service),
            quantity: service.value
          };
          break;
        case ("VIPLOUNGE"):
          item = {
            productCode: service.name,
            productId: getServiceId(service),
            quantity: service.value,
            data: service.data
          };
          break;
        case ("TLDRST"):
          item = {
            productCode: service.name,
            productId: getServiceId(service),
            quantity: service.value,
            data: service.data
          };
          break;
        case ("TLDRFM"):
          item = {
            productCode: service.name,
            productId: getServiceId(service),
            quantity: service.value,
            data: service.data
          };
          break;
        default:
          item = {
            productCode: service.name,
            productId: getServiceId(service),
            quantity: service.value
          };
          break;
      }
      cartItems.push(item)
    })

    let order = {
      customerName: location.state.data.name,
      customerSurname: location.state.data.surname,
      currency: props.currencyDuck.currency,
      items: cartItems,
      paymentNonce: state.paymentMethods[state.selected].token
    }

    setState({
      ...state,
      paymentLoading: true
    })
    await doPayment(order);

    setState({
      ...state,
      paymentLoading: false
    })

    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}profile/orders`);
  }

  return (
    <Col className="centered">
      {
        state.loading &&
        <Spin />
      }
      {
        !state.loading && state.paymentMethods.length > 0 &&
        <Col className={"w-100"}>
          {
            state.paymentMethods.map((method, index) => {
              return (
                <div key={index}>
                  <PaymentMethodCard
                    src={method.imageUrl}
                    cardType={method.details.cardType}
                    last4={method.details.last4}
                    expiring={method.details.expirationDate}
                    selected={state.selected}
                    i={index}
                    handleSelect={selectMethod(index)}
                  />
                </div>
              )
            })
          }
          <Col className={"b2b-payment-confirm-button-container"}>
            <CustomButton
              content={state.paymentLoading ? "" : t("checkout.paymentConfirm")}
              clickCallback={confirmMethod}
              isLoading={state.paymentLoading}
            />
          </Col>
        </Col>
      }
      {
        !state.loading && state.paymentMethods.length === 0 &&
        <Col className={"b2b-payment-no-available-methods"}>
          {t("checkout.noAvailableMethods")}. <br/>
          {t("checkout.contactYourManager")}.
        </Col>
      }


    </Col>
  )
}

const mapStateToProps = state => ({
  productsListDuck: state.productsListDuck,
  servicesDuck: state.servicesDuck,
  currencyDuck: state.currencyDuck,
  userInfoDuck: state.userInfoDuck,
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(B2B_Payment)