import React, { useEffect, useState } from "react";

// STYLES
import './FaqPage.css';

// ASSETS
import luggageIcon from '../../assets/images/faqs/luggage-icon-black.svg';

// COMPONENTS
import SEO from "../../components/functional_components/SEO/SEO";

import { Typography, Col, Row, Image, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getValueFromLang } from "../../utils/utilities";

const FaqPage = (props) => {

  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const location = useLocation();
  const { Panel } = Collapse;

  const [state, setState] = useState({
    faqs: null,
    faqSection: null
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    createFaqGroups()
  }, [location])

  const createFaqGroups = () => {
    let lang = props.languageDuck.currentLanguage;
    let data = props.faqsDuck.faqs.data.components;
    let newObj = [];
    let newFaqSection = [];

    for (let i = 0; i < 5; i++) {
      let a;
      let b;

      data.map((el) => {
        if (el.id === `categoryFaq${i}`) {
          let category = getValueFromLang(el.translations, lang, true);
          let categoryGroup = [];

          if (i === 0) {
            a = 0;
            b = 4;
          } else if (i === 1) {
            a = 4;
            b = 8;
          } else if (i === 2) {
            a = 8;
            b = 12;
          } else if (i === 3) {
            a = 12;
            b = 16;
          } else if (i === 4) {
            a = 16;
            b = 20;
          }

          for (let j = a; j < b; j++) {
            let singleFaq = {};
            props.faqsDuck.faqs.data.components.map((faq) => {
              if (faq.id === `faq${j}Answer`) {
                singleFaq.answer = getValueFromLang(faq.translations, lang, true);
              }
              if (faq.id === `faq${j}Question`) {
                singleFaq.question = getValueFromLang(faq.translations, lang, true)
              }
            })
            if (singleFaq.answer && singleFaq.question) {
              categoryGroup.push(singleFaq)
            }
          }

          if (category && categoryGroup.length > 0) {
            newFaqSection.push(category)
            newObj.push([{ category: category, faqs: categoryGroup }])
          }
        }
      })
    }

    setState({
      faqs: newObj,
      faqSection: newFaqSection
    })
  }

  const handleSiderLink = (id) => () => {
    let siderElement = document.getElementById(`faqs-page-sider-link-${id}`);
    let element = document.getElementById(`home-faq-container-${id}`);
    let rect = element.getBoundingClientRect();
    let scrollTop = rect.top - 120;

    for (let i = 0; i < state.faqSection.length; i++) {
      let el = document.getElementById(`faqs-page-sider-link-${i}`);
      el.classList.remove('active');
      console.log(el)
    }
    siderElement.classList.add('active')

    window.scrollTo({ top: scrollTop, left: 0, behavior: "smooth" })
  }

  const faqSection = (section) => {
    return state.faqs.map((faqGroups) => {
      return faqGroups.map((group, i) => {
        if (group.category === section) {
          return (
            <div className={'home-faq-container faqs-page-container'} key={i}>
              <Collapse defaultActiveKey={0} accordion style={{ width: "100%" }}>
                {
                  group.faqs.map((faq, key) => {
                    return (
                      <Panel key={key} header={faq.question} className={'home-faq-panel'}>
                        {faq.answer}
                      </Panel>
                    )
                  })
                }
              </Collapse>
            </div>
          )
        }
      })
    })
  }

  return (
    <Row className={"faqs-main-page-container"}>
      <SEO
        page={"FAQ"}
        url={"faqs"}
      />

      <Col className={"faqs-page-sider-links-container"}>
        {
          state.faqSection &&
          state.faqSection.map((section, i) => {
            return (
              <div
                className={i === 0 ? "faqs-page-sider-link active" : "faqs-page-sider-link"}
                id={`faqs-page-sider-link-${i}`}
                onClick={handleSiderLink(i)}
                key={i}
              >
                {section}
              </div>
            )
          })
        }
      </Col>
      <Col className={"faqs-page-content-container"}>
        <Col className={"faqs-page-title-container"}>
          <Title className={"faqs-page-title"}>{t("home.FAQ.title")}</Title>
          <Text className={"faqs-page-subtitle"}>{t("home.FAQ.subtitle")}</Text>
        </Col>
        <Col>
          {
            state.faqSection &&
            state.faqSection.map((section, i) => {
              return (
                <div key={i} id={`home-faq-container-${i}`}>
                  <Row className={"faqs-page-section-title-container"}>
                    <Image
                      src={luggageIcon}
                      alt={section}
                      preview={false}
                    />
                    <Text className={"faqs-page-section-title"}>{i + 1}. {section}</Text>
                  </Row>
                  {faqSection(section)}
                </div>
              )
            })
          }
        </Col>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => ({
  faqsDuck: state.faqsDuck,
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(FaqPage)