import React from "react";

// STYLES
import './PreCheckout.css';

// COMPONENTS
import CheckoutServiceCard from "../../../components/functional_components/checkoutServiceCard/CheckoutServiceCard";
import CustomButton from "../../../components/functional_components/customButton/CustomButton";
import FooterCheckout from "../../../components/functional_components/footerCheckout/FooterCheckout";

// REDUX
import { connect } from "react-redux";

import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";
import { formatValue, getValueFromLang } from "../../../utils/utilities";

const PreCheckout = (props) => {

  let availableServices = props.availableServicesDuck.availableServices;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}services-checkout`);
  }

  const getPrice = (service) => {
    let productPrice;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        product.prices.map((price) => {
          if (price.currency === props.currencyDuck.currency) {
            productPrice = price.price;
          }
        });
      }
    });
    return productPrice;
  }

  const getBadge = (service) => {
    let badge;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        badge = product.iconImage
      }
    });
    return badge;
  }

  const getTarget = (service) => {
    let target;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        target = product.target;
      }
    });
    return target;
  }

  return (
    <Col>
      <Row className={"pre-checkout-cards-container"}>
        {
          availableServices.map((service) => {
            return (
              props.productsListDuck.productsList.data.map((product, index) => {
                if (product.code === service) {
                  return (
                    <div key={index}>
                      <CheckoutServiceCard
                        cardCode={product.code}
                        cardTitle={getValueFromLang(product.name, props.languageDuck.currentLanguage)}
                        cardSubtitle={getValueFromLang(product.subtitle, props.languageDuck.currentLanguage)}
                        cardDescription={getValueFromLang(product.cardDescription, props.languageDuck.currentLanguage)}
                        priceForItem={formatValue(getPrice(service))}
                        target={getTarget(service)}
                        badge={getBadge(service)}
                        currency={props.currencyDuck.currency === "EUR" ? "€" : "$"}
                      />
                    </div>
                  )
                }
              })
            )
          })
        }
      </Row>

      <div className={"pre-checkout-content-button-container"}>
        <CustomButton
          type={'primary'}
          content={t("checkout.goToCheckout")}
          style={{
            width: '288px',
            height: '48px',
            fontSize: "16px"
          }}
          clickCallback={handleClick}
          isDisabled={props.servicesDuck.services.length > 0 ? false : true}
        />
      </div>

      <FooterCheckout />
    </Col>
  )
}

const mapStateToProps = state => ({
  servicesDuck: state.servicesDuck,
  availableServicesDuck: state.availableServicesDuck,
  productsListDuck: state.productsListDuck,
  currencyDuck: state.currencyDuck,
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(PreCheckout)